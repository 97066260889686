/**
 * jQuery plugin to handle the cookie layer.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @example <caption>Basic plugin usage</caption>
 *  $('.layer').cookieLayer({
 *      accepted: false,
 *      onChangeAccepted: () => console.log("changed")
 *  });
 *
 * @requires jquery
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */
import $ from 'jquery';
import { callFn } from '../utils/function';

const PLUGIN_NAME = 'cookieLayer';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.0.1';
const noop = Function.prototype;

const DEFAULTS = {
    sel: {
        layer: '.m-layer--cookie-layer',
        btn: '.c-btn'
    },
    accepted: false,
    onChangeAccepted: noop
};

/**
 * @class
 */
class CookieLayer {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     * @returns {void}
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            { callback }
        );

        this.version = VERSION;
        this.cache = {
            $el,
            opts
        };

        this.init();
    }

    /**
     * Init plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const {
            opts: { callback, sel, accepted, onChangeAccepted },
            $el
        } = this.cache;

        if (accepted) {
            $el.hide();
            return;
        }

        $el.show();
        const $button = $el.find(sel.btn);
        $button.off('.cookie-layer').on({
            'click.cookie-layer': function () {
                $el.hide();
                onChangeAccepted(!accepted);
            }
        });

        callFn(callback);
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link CookieLayer}
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback=noop] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new CookieLayer(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
