/*global window, history */
/**
 * jQuery plugin to load html pages via ajax.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.2
 *
 * @TODO: Prevent multiple loads, cancel ajax request when other button is clicked
 *
 * @example <caption>Basic plugin usage</caption>
 * $('body').page({}, function () {
 *    console.log("ready");
 * });
 *
 * @see {@link https://css-tricks.com/using-the-html5-history-api/}
 *
 * @requires jquery
 * @requires lodash
 * @requires vendor/requestAnimationFrame
 * @requires utils/environment
 * @requires utils/function
 * @requires utils/scroll-to
 * @requires utils/xhr
 *
 * @changelog
 * - 0.0.2 Add requestAnimationFrame for initial builds
 * - 0.0.1 Basic function and structure
 */
import $ from 'jquery';
import { isFunction, get } from 'lodash';
import '../vendor/requestAnimationFrame';
import { isProduction } from '../utils/environment';
import { callFn } from '../utils/function';
import scrollTo, { getPageOffset } from './../utils/scroll-to';
import { xhr } from '../utils/xhr';

const PLUGIN_NAME = 'page';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.0.1';

const $window = $(window);
const noop = Function.prototype;

/**
 * @private
 * @type {Object}
 * @property {Object} sel - Css selector config
 * @property {number} [elementHeight=61] - Initial height for the element
 * @property {boolean} [elementVisible=true] - Initial flag if element is visible
 * @property {number} [previousScrollY=0] - Initial window scroll
 */
const DEFAULTS = {
    sel: {
        title: 'title',
        links: 'a[href]',
        options: '.page-select',
        layoutHeader: '.l-header',
        layoutMain: '.l-main',
        layoutFooter: '.l-footer',
        metaTitle: 'title',
        isLoading: '.is-loading',
        isActive: '.is-active'
    },
    timeout: 0,
    scrollToTop: true
};

/**
 * @class
 */
class Page {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     * @returns {void}
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            { callback }
        );

        const { sel } = opts;
        this.version = VERSION;
        this.cache = {
            $el,
            $main: $el.find(sel.layoutMain),
            $header: $el.find(sel.layoutHeader),
            $footer: $el.find(sel.layoutFooter),
            $title: $(sel.metaTitle),
            $links: $el.find(sel.links),
            opts,
            hostname: window.location.hostname
        };

        this.onClick = this.onClick.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onPopstate = this.onPopstate.bind(this);

        this.scrollTop = this.scrollTop.bind(this);
        this.isLoading = this.isLoading.bind(this);
        this.pushState = this.pushState.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.dom = this.dom.bind(this);
        this.plugins = this.plugins.bind(this);

        this.xhr = null;

        this.init();
    }

    /**
     * Scroll to top after loading success.
     *
     * @returns {void}
     */
    scrollTop() {
        return new Promise((resolve) => {
            if (!this.cache.opts.scrollToTop && !getPageOffset()) {
                return resolve();
            }
            scrollTo({
                top: 0
            });
            return resolve();
        });
    }

    /**
     * Refresh dom vars after dom update or
     * initial loading.
     *
     * @returns {void}
     */
    dom() {
        const { $el, opts: { sel } } = this.cache;

        const $links = $el.find(sel.links);
        $links.off('.page').on({
            'click.page': this.onClick
        });
        const $options = $el.find(sel.options);
        $options.off('.page').on({
            'change.page': this.onClick
        });

        this.cache.$links = $links;
    }

    /**
     * Init all jquery functions and plugins via
     * provided callback.
     *
     * @returns {Promise}
     */
    plugins() {
        const {
            opts: { callback }
        } = this.cache;

        return new Promise((resolve) => {
            callFn(callback);
            resolve();
        });
    }

    /**
     * Display loading animation if necessary.
     *
     * @param {boolean} [show=false] - Whether to show or hide the loading layer
     * @returns {Promise}
     */
    isLoading(show = false) {
        const {
            $main,
            opts: { sel }
        } = this.cache;

        return new Promise((resolve) => {
            if (show) {
                $main.addClass(sel.isLoading.substr(1));
            } else {
                $main.removeClass(sel.isLoading.substr(1));
            }
            resolve();
        });
    }

    /**
     * Load page content async via fetch.
     *
     * @param {string} url - The page url to be loaded
     * @returns {Promise}
     */
    request(url) {
        this.isLoading(true);
        this.cache.url = url;
        if (this.xhr) {
            this.xhr.abort();
        }

        return new Promise((resolve, reject) => {
            if (!url) {
                return reject({
                    message: 'No page url provided'
                });
            }
            this.xhr = xhr();
            return this.xhr.fetch(url).then(function (response) {
                return response.text();
            }).then(resolve).catch(reject);
        });
    }

    /**
     * Add newly loaded url to browser history.
     *
     * @param {string} [url=this.cache.url] - 
     * @returns {Promise}
     */
    pushState(url = this.cache.url) {
        return new Promise((resolve) => {
            if (window.history && isFunction(window.history.pushState)) {
                // Sadly needed due to some non-standard implementations
                // in e.g. JSDom
                try {
                    window.history.pushState(url, null, `${url}`);
                } catch (ignore) {
                    // eslint-disable-line no-empty
                }
            }
            return resolve();
        });
    }

    /**
     * Ajax success handler.
     *
     * @param {string} [data] - The loaded html data
     * @returns {Promise}
     */
    onSuccess(data) {
        const {
            $main,
            $header,
            $footer,
            $title,
            opts: { sel }
        } = this.cache;

        this.xhr = null;

        return new Promise((resolve, reject) => {
            if (!data) {
                return reject({
                    message: 'No html data found'
                });
            }

            const $html = $($.parseHTML(data));
            const $newMain = $html.filter(sel.layoutMain);
            if (!$newMain.length) {
                return reject({
                    message: 'No main element found'
                });
            }

            const $newHeader = $html.filter(sel.layoutHeader);
            const $newFooter = $html.filter(sel.layoutFooter);
            const $newTitle = $html.filter(sel.title);

            requestAnimationFrame(function () {
                $header.html($newHeader.html());
                $main.html($newMain.html());
                $footer.html($newFooter.html());
                $title.html($newTitle.html());

                resolve();
            });
        });
    }

    /**
     * Ajax error handler.
     *
     * @param {string} [reason] - The error text status
     * @returns {void}
     */
    onError(reason) {
        // Swallow fetch aborts
        if (reason.name == 'AbortError') {
            !isProduction() && console.warn('Fetch request aborted'); // eslint-disable-line no-console
            return;
        }

        !isProduction() && console.warn(reason); // eslint-disable-line no-console
        this.isLoading();
        this.xhr = null;
    }

    /**
     * Handle link click events.
     *
     * @param {Object} event - The current event object
     * @returns {true} Allow people middle or command click so that we don't override them accidentally
     */
    onClick(event) {
        const { hostname } = this.cache;
        const url = get(event, 'currentTarget.href') || get(event, 'target.value');

        if (!url || !url.includes(hostname)) {
            return true;
        }

        event.preventDefault();
        event.stopPropagation();

        if (url === window.location.href) {
            return true;
        }

        this.scrollTop()
            .then(() => {
                return this.request(url);
            })
            .then(this.onSuccess)
            .then(this.isLoading)
            .then(this.pushState)
            .then(this.plugins)
            .then(this.dom)
            .catch(this.onError);
        
        return true;
    }

    /**
     * Handle html5 history popstate, e.g. while
     * clicking the browser back button.
     *
     * @returns {Promise}
     */
    onPopstate() {
        const url = history && history.state;

        this.request(url)
            .then(this.onSuccess)
            .then(this.isLoading)
            .then(this.plugins)
            .then(this.dom)
            .then(this.scrollTop)
            .catch(this.onError);
    }

    /**
     * Handle window load.
     *
     * @returns {Promise}
     */
    onLoad() {
        this.plugins();
    }

    /**
     * Setup plugin, main function.
     *
     * @returns {void}
     */
    init() {
        this.dom();

        $window.on({
            'popstate.page': this.onPopstate,
            'load.page': this.onLoad
        });
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link Page}
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback=noop] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Page(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
