/**
 * Check if value is a valid select/option value.
 *
 * @param {string} value - The value to be checked
 * @returns {boolean} Whether the value is valid or not
 */
function isValidValue(value) {
    return value !== undefined && value !== false && value !== '';
}

export default isValidValue;
