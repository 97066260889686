/**
 * Redux configuration for featured state handling.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @changelog
 * - 0.0.1 Basic function and structure
 */

/**
 * Action name for changing selected item.
 * Just exported for test purposes.
 *
 * @type {string}
 */
export const CHANGE_COOKIE_ACCEPTED = 'CHANGE_COOKIE_ACCEPTED';

/**
 * Resource name for the store object.
 *
 * @type {string}
 */
export const COOKIE_RESOURCE_NAME = 'cookie';

/**
 * Default state for the store.
 *
 * @type {Object}
 */
export const DEFAULT_STATE = {
    accepted: false
};

/**
 * Trigger e accepted cookie state changes.
 *
 * @param {string} accepted - The accepted cookie state
 * @returns {void}
 */
export function changeCookieAccepted(accepted) {
    return {
        type: CHANGE_COOKIE_ACCEPTED,
        accepted
    };
}

/**
 * Handle cookie state changes.
 *
 * @param {Object} state - The current redux state
 * @param {Object} action - The current action
 * @returns {Object} The (modified) redux state
 */
export function reducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
    case CHANGE_COOKIE_ACCEPTED:
        return {
            ...state,
            accepted: action.accepted
        };
    default:
        return state;
    }
}

/**
 * Convinient helper to export the reducer with
 * the corresponding redux store object key.
 *
 * @type {Object}
 */
export const reducerCookie = {
    [COOKIE_RESOURCE_NAME]: reducer
};
