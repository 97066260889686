import $ from 'jquery';
import renderOption from './render-option';

/**
 * Helper function ro render option replacements.
 *
 * @param {Object} sel - CSS selector config
 * @param {Function} [fnCustomOption] - Custom render callback for generating entry html
 * @param {jQuery} $element - The current jQuery option object
 * @param {jQuery} $content - The jQuery object where the generated option replacement should be appended to
 * @param {string} id - The current option replacement id
 * @returns {jQuery} The current element
 */
function renderOptions(sel, fnCustomOption, $element, $content, id) {
    const options = $.makeArray($element.find(sel.option));

    return options.map(function (option, i) {
        return renderOption(sel, fnCustomOption, $(option), $content, id + '--' + i);
    });
}

export default renderOptions;
