import { isFunction } from 'lodash';

/**
 * Get css class from string selector, to be used with jQuery's
 * helper function addClass and removeClass.
 *
 * @param {string} string - The css string to parse
 * @param {string} [type='.'] - The css selector type (./#)
 * @param {string} [separator=','] - The string separator
 * @returns {string} The css selector name
 */
function getCssClass(string, type = '.', separator = ',') {
    if (!string || !isFunction(string.split)) {
        return '';
    }

    const stringParts = string.split(separator);
    return stringParts.filter(function (part) {
        return part.trim().length;
    }).reduce(function (acc, part) {
        const trimmedPart = part.replace(type, '').trim();
        if (trimmedPart.length) {
            acc.push(trimmedPart);
        }
        return acc;
    }, []).join(' ');
}

export {
    getCssClass
};
