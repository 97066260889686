import { isString } from 'lodash';
import { isBrowser } from './environment';

const IS_BROWSER = isBrowser();

/**
 * Parse query string object.
 *
 * @param {string} [query] - The query string, otherwise window.location
 * @returns {Object<string, string>|null} The parsed query config
 */
function getQueryString(query) {
    const searchString = query || (IS_BROWSER && window.location.search.substring(1));
    if (!isString(searchString)) {
        return null;
    }

    return searchString
        .split('&')
        .reduce(function (acc, current) {
            const pair = current.split('=');
            const key = pair[0];
            const value = pair[1];

            // If first entry with this name
            if (acc[key] === undefined) {
                acc[key] = value;
                return acc;
            }

            // If second entry with this name
            if (isString(acc[key])) {
                const arr = [acc[key], value];
                acc[key] = arr;
                return acc;
            }

            // If third or later entry with this name
            acc[key].push(value);
            return acc;
        }, {});
}

export {
    getQueryString
};