import $ from 'jquery';
import { isFunction } from 'lodash';

import {
    callFn,
    getCssClass
} from './../../utils';
import { isValidValue } from './index';

const ATTR_DISABLED = 'disabled';
const ATTR_SELECTED = 'selected';

/**
 * Helper function ro render single option replacement.
 *
 * @param {Object} sel - CSS selector config
 * @param {Function} [fnCustomOption] - Custom render callback for generating entry html
 * @param {jQuery} $option - The current jQuery option object
 * @param {jQuery} $content - The jQuery object where the generated option replacement should be appended to
 * @param {string} id - The current option replacement id
 * @returns {jQuery} The newly created option
 */
function renderOption(sel, fnCustomOption, $option, $content, id) {
    const {
        contentEntryTag,
        contentEntryId,
        contentEntryCss,
        isDisabled,
        isSelected
    } = sel;
    let $entry = $(`<${contentEntryTag}>`, {
        'id': contentEntryId ?
            `${getCssClass(contentEntryId, '#')}--${id}`
            : id,
        'class': getCssClass(contentEntryCss),
        'role': 'option',
        'tabindex': '-1',
        'html': $option.text()
    });

    // Check for custom content
    if (isFunction(fnCustomOption)) {
        $entry.html(callFn(fnCustomOption, [$option]));
    }

    // Store current option value in javascript data and html5 data attribute
    const value = $option.attr('value') || $option.val();
    if (isValidValue(value)) {
        $entry
            .data('value', value)
            .attr('data-value', value);
    }

    // Check disabled state
    if ($option.attr(ATTR_DISABLED)) {
        $entry.addClass(getCssClass(isDisabled));
    }

    // Check selected status
    if ($option.attr(ATTR_SELECTED) && $option.attr(ATTR_SELECTED) === ATTR_SELECTED) {
        $entry.addClass(getCssClass(isSelected));
        $content.attr('aria-activedescendant', $entry.attr('id'));
    }

    return $entry;
}

export default renderOption;
