import $ from 'jquery';
import { getCssClass } from './../../utils';

/**
 * Helper function to render single option replacement.
 *
 * @param {Object} sel - The current plugin store
 * @param {string} html - The html label content to be rendered
 * @param {jQuery} $content - The jQuery object where the generated option replacement should be appended to
 * @param {string} id - The current option replacement id
 * @returns {jQuery}
 */
function renderOptGroup(sel, html, $content, id) {
    const {
        contentEntryTag,
        contentEntryId,
        contentEntryCss
    } = sel;
    const $wrapper = $(`<${contentEntryTag}>`, {
        'id': contentEntryId ?
            `${getCssClass(contentEntryId, '#')}--${id}`
            : id,
        'class': `${getCssClass(contentEntryCss)} is-optgroup`,
        'role': 'option',
        'tabindex': '-1',
        'html': html
    });

    return $wrapper.appendTo($content);
}

export default renderOptGroup;
