/**
 * Es6 module for Redux Architecture.
 * This file takes care for loading and storing the redux store state in a
 * web-storage (local storage) to improve initial loading time.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @requires constants/core
 * @requires utils/environment
 * @requires utils/xor
 * @requires utils/web-storage
 *
 * @changelog
 * - 0.0.1 basic functions and structure
 */
import { STATE_RESOURCE_NAME, XOR_ENABLED, XOR_KEY } from '../constants/core';
import { isProduction } from '../utils/environment';
import WebStorage from '../utils/web-storage';
import { encrypt, decrypt } from '../utils/xor';

const webStorage = new WebStorage();

/**
 * Load saved state and return undefined to let reducers init
 * application default state.
 *
 * @function
 * @returns {Object|undefined}
 */
function loadState() {
    const serializedState = webStorage.read(STATE_RESOURCE_NAME);

    if (serializedState === null) {
        return undefined;
    }

    /**
     * Parsing could fail if we change the xor key or the stored data is corrupt,
     * so we have to use try catch here even this reduces performance
     */
    try {
        return JSON.parse(
            XOR_ENABLED
                ? decrypt(serializedState, XOR_KEY)
                : serializedState
        );
    } catch (reason) {
        // eslint-disable-next-line no-console
        !isProduction() && console.warn(reason);
        return undefined;
    }

}

/**
 * Save the given application state.
 *
 * @function
 * @param {Object} state - The state object to be saved
 * @returns {void}
 */
function saveState(state) {
    webStorage.save(
        STATE_RESOURCE_NAME,
        XOR_ENABLED
            ? encrypt(JSON.stringify(state), XOR_KEY)
            : JSON.stringify(state)
    );
}

export {
    loadState,
    saveState
};
