/**
 * jQuery plugin for displaying elements based on scroll
 * direction and position.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @example <caption>Basic plugin usage</caption>
 * $('header').fixed({ headerHeight: 20 }, function () {
 *    console.log("ready");
 * });
 *
 * @requires jquery
 * @requires lodash
 * @requires utils/function
 * @requires utils/scroll-to
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */
import $ from 'jquery';
import { throttle } from 'lodash';
import { callFn } from '../utils/function';
import { getPageOffset } from './../utils/scroll-to';

const PLUGIN_NAME = 'fixed';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.0.1';

const noop = Function.prototype;
const $window = $(window);

/**
 * @private
 * @type {Object}
 * @property {Object} sel - Css selector config
 * @property {number} [elementHeight=61] - Initial height for the element
 * @property {boolean} [elementVisible=true] - Initial flag if element is visible
 * @property {number} [previousScrollY=0] - Initial window scroll
 */
const DEFAULTS = {
    sel: {
        isVisible: '.is-visible'
    },
    elementHeight: 61,
    elementVisible: true,
    previousScrollY: 0
};

/**
 * @class
 */
class Fixed {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            { callback }
        );

        this.version = VERSION;
        this.cache = {
            $el,
            opts,
            previousScrollY: opts.previousScrollY,
            elementVisible: opts.elementVisible
        };

        this.onScroll = throttle(this.onScroll.bind(this), 100);
        this.init();
    }

    /**
     * Handle window scrolling.
     * 
     * @returns {void}
     */
    onScroll() {
        const { $el, elementVisible, previousScrollY, opts: { elementHeight, sel } } = this.cache;
        const currentScrollY = getPageOffset();
        const isVisibleClassName = sel.isVisible.substr(1);
        let newElementVisible = true;

        /**
         * User is scrolling up, so show header.
         * Also checking if initial scroll position is used.
         */
        if (
            (previousScrollY > currentScrollY && !elementVisible) ||
            (previousScrollY === 0 && currentScrollY === 0)
        ) {
            newElementVisible = true;
        }

        // User is scrolling down, so hide header.
        if (previousScrollY < currentScrollY && elementHeight < currentScrollY) {
            newElementVisible = false;
        }

        // Just add classNames if necessary
        if (newElementVisible && !elementVisible) {
            $el.addClass(isVisibleClassName);
        }
        if (!newElementVisible && elementVisible) {
            $el.removeClass(isVisibleClassName);
        }

        this.cache = {
            ...this.cache,
            elementVisible: newElementVisible,
            previousScrollY: currentScrollY
        };
    }

    /**
     * Init plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const { opts: { callback } } = this.cache;

        $window.on('scroll', this.onScroll);
        this.onScroll();

        callFn(callback);
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link Fixed}
 * @param {Object} [options] - The optional plugin options
 * @param {Function} [callback=noop] - The optional plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Fixed(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
