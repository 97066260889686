/**
 * jQuery plugin to render a filter menu for featured
 * preview items.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @example <caption>Basic plugin usage</caption>
 * $('.featured').featured({ delay: 50, onFilter: function () {
 *    console.log('items filtered')
 * }}, function () {
 *    console.log('ready');
 * });
 *
 * @requires jquery
 * @requires vendor/requestAnimationFrame
 * @requires utils/environment
 * @requires utils/get-css-class
 * @requires utils/function
 *
 * @changelog
 * - 0.0.3 Improve redux state management
 * - 0.0.2 Add requestAnimationFrame for initial builds
 * - 0.0.1 Basic function and structure
 */
import $ from 'jquery';
import '../vendor/requestAnimationFrame';
import { isProduction } from '../utils/environment';
import { getCssClass } from '../utils/get-css-class';
import { callFn } from '../utils/function';

const PLUGIN_NAME = 'featured';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.0.3';

const noop = Function.prototype;

/**
 * @private
 * @type {Object}
 * @property {Object} data - Html data attributes to read from
 * @property {number} delay - Animation delay for adding/removing classNames
 * @property {Function} onFilter - Callback after filtering
 * @property {Object} sel - Css selector config
 * @property {string} selected - Initual selected filter value based on data category
 */
const DEFAULTS = {
    data: {
        category: 'category',
        categoryAll: 'categoryAll'
    },
    delay: 500,
    onFilter: noop,
    sel: {
        border: '.c-border',
        borderLine: '.c-border__line',
        filterInput: 'm-featured__input',
        filterBar: '.m-nav--filter',
        filterMenu: '.m-menu--filter',
        filterMenuItemAll: '.m-menu__item--all',
        filterMenuItems: '.m-menu__item',
        filterMenuListItems: '.m-menu__list-item',
        filterMenuButtonLabel: '.m-menu__label',
        filterSelect: '.m-select--filter',
        isActive: '.is-active',
        isInitialized: '.is-initialized',
        isLoading: '.is-loading',
        items: '.m-featured__item'
    },
    selected: null
};

/**
 * Create category array to be stored. Will be called recursively
 * when category contains multiple entries.
 *
 * @private
 * @param {Array<string>} categories - The categories array
 * @param {Array} [target=[]] - The target new categories are added to
 * @returns {Array} The newly created array
 */
function createCategries(categories, target = []) {
    return categories.reduce(function (accumulator, category) {
        // Multiple categories defined, so we use recursion here
        if (category.includes(',')) {
            return createCategries(category.split(',').map(function (cat) {
                return cat.trim();
            }), accumulator);
        }

        // Add current single category
        if (category) {
            accumulator.push({
                name: category,
                length: 1
            });
        }

        return accumulator;
    }, target);
}

/**
 * jQuery featured filter plugin.
 *
 * @class
 */
class Featured {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options={}] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     * @returns {void}
     */
    constructor(element, options = {}, callback) {
        const $el = $(element);
       
        const opts = Object.assign(
            {},
            DEFAULTS,
            options,
            { callback }
        );

        this.version = VERSION;
        this.cache = {
            $el,
            opts,
            selected: opts.data.categoryAll
        };
        this.filter = this.filter.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.createFilterMenu = this.createFilterMenu.bind(this);
        this.createFilterSelect = this.createFilterSelect.bind(this);

        return this.init();
    }

    /**
     * Collect categories with counter and save them in store.
     *
     * @returns {void}
     */
    getCategories() {
        const { categoryAll, $items, opts } = this.cache;
        const excludes = [
            'undefined'
        ];

        // Create config with length counter
        let categories = createCategries(
            $items.toArray().map(function (item) {
                return String($(item).data(opts.data.category)).trim();
            })
        ).filter(function (category) {
            return !excludes.includes(category.name);
        }).sort(function (a, b) {
            return a.name.localeCompare(b.name);
        }).reduce(function (acc, category) {
            const prev = acc[acc.length - 1];
            if (prev && prev.name === category.name) {
                prev.length = prev.length + 1;
            } else {
                acc.push(category);
            }
            return acc;
        }, []);

        // Add "all" category
        categories.unshift({
            name: categoryAll,
            length: $items.length
        });

        this.cache = {
            ...this.cache,
            categories,
            $items
        };
    }


    /**
     * Create filter menu list.
     *
     * @returns {void}
     */
    createFilterMenu() {
        const {
            categories,
            $el,
            $filterBar,
            opts: { data, sel }
        } = this.cache;

        const $filterMenu = $('<ul />')
            .attr({
                role: 'menu',
                itemscope: '',
                itemtype: 'https://schema.org/ItemList'
            })
            .addClass(getCssClass(sel.filterMenu));

        const classNameListItem = `${getCssClass(sel.filterMenuListItems)} ${getCssClass(sel.border)}`;
        const classNameButton = function (index) {
            return `${getCssClass(sel.filterMenuItems)} ${index === 0 ? [getCssClass(sel.isActive), getCssClass(sel.filterMenuItemAll)].join(' ') : ''} c-link`;
        };
        const classNameButtonLabel = `${getCssClass(sel.filterMenuButtonLabel)} ${getCssClass(sel.borderLine)}`;

        const filterMenuListItemArray = categories.map(function (category, index) {
            const categoryName = category.name;

            return $(`
                <li class="${classNameListItem}" itemprop="itemListElement" itemscope itemtype="http://www.schema.org/SiteNavigationElement">
                    <button
                        class="${classNameButton(index)}"
                        data-${data.category}="${categoryName}"
                        tabindex="0"
                        title="${categoryName}"
                        itemprop="url"
                        role="menuitem">
                        <span class="${classNameButtonLabel}" itemprop="name">
                            <span class="m-menu__label-name">${categoryName}</span>
                            <sup class="m-menu__count">(${category.length})</sup>
                        </span>
                    </button>
                </li>
            `);
        });

        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                $filterMenu
                    .append(filterMenuListItemArray)
                    .appendTo($filterBar);

                const $filterMenuItems = $filterMenu
                    .find(sel.filterMenuItems)
                    .on({
                        'click.featured': function (e) {
                            e.preventDefault();
                            if ($(this).hasClass(getCssClass(sel.isActive))) {
                                return;
                            }
                            $el.trigger('filter.featured', $(this).data(data.category));
                        }
                    });

                this.cache = {
                    ...this.cache,
                    $filterMenu,
                    $filterMenuItems
                };
                resolve();
            });
        });
    }

    /**
     * Create filter select dropdown.
     *
     * @returns {void}
     */
    createFilterSelect() {
        const {
            categoryAll,
            categories,
            $el,
            $filterBar,
            opts: { sel }
        } = this.cache;

        const $filterSelect = $('<select />')
            .addClass(getCssClass(sel.filterSelect));

        const $filterSelectOptions = categories.map(function (category) {
            return $(`
                <option ${category.name === categoryAll ? 'selected="selected" ' : ' '}value="${category.name}">
                    ${category.name} (${category.length})
                </option>
            `);
        });

        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                $filterSelect
                    .append($filterSelectOptions)
                    .appendTo($filterBar)
                    .on({
                        'change.featured': function (e, category) {
                            $el.trigger('filter.featured', category || $(this).val());
                        }
                    });

                this.cache = {
                    ...this.cache,
                    $filterSelect
                };
                resolve();
            });
        });
    }

    /**
     * Keep different filters in sync after change.
     *
     * @param {string} category - The category id to be filtered for
     * @returns {void}
     */
    syncFilters (category) {
        const { $filterMenuItems, $filterSelect, opts: { data, sel } } = this.cache;

        // Sync select input
        const selectValue = $filterSelect.val();
        if (selectValue !== category) {
            // Event namespacing not possible here
            $filterSelect.val(category).trigger('change');
        }

        // Sync filter menu
        $filterMenuItems.each(function () {
            const $button = $(this);
            if ($button.data(data.category) !== category) {
                $button.removeClass(getCssClass(sel.isActive));
            } else {
                $button.addClass(getCssClass(sel.isActive));
            }
        });
    }

    /**
     * Handle filtering triggered by change.
     *
     * @param {string} category - The category id to be filtered for
     * @returns {void}
     */
    filter(category) {
        const { categoryAll, $items, opts } = this.cache;
        const delay = Number(opts.delay);
        const cat = String(category);

        const $filteredItems = $items.filter(function () {
            const $this = $(this);
            const data = $this.data(opts.data.category);
            return cat === categoryAll || (data && data.includes(cat));
        });

        clearTimeout(this.cache.timer1);
        clearTimeout(this.cache.timer2);
        clearTimeout(this.cache.timer3);

        this.cache.timer1 = setTimeout(function () {
            $items.addClass('disappear');
        }, 0);
        this.cache.timer2 = setTimeout(function () {
            $items.addClass('is-hidden');
            $filteredItems.removeClass('is-hidden');
        }, delay);
        this.cache.timer3 = setTimeout(function () {
            $filteredItems.removeClass('disappear');
        }, delay + 50);

        this.syncFilters(category);
        opts.onFilter(category);

        this.cache.selected = category;
    }

    /**
     * Store cached versions of often used variables.
     *
     * @returns {void}
     */
    dom() {
        const { $el, opts } = this.cache;

        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                const $items = $el.find(opts.sel.items);
                const $filterBar = $(opts.sel.filterBar);
                const categoryAll = $el.data(opts.data.categoryAll) || '';

                this.cache = {
                    ...this.cache,
                    $items,
                    $filterBar,
                    categoryAll
                };

                resolve();
            });
        });
    }

    /**
     * Main function, run basic functions.
     *
     * @returns {void}
     */
    init() {
        const { $el, opts: { callback, delay, sel, selected } } = this.cache;

        return this.dom()
            .then(this.getCategories)
            .then(this.createFilterMenu)
            .then(this.createFilterSelect)
            .then(() => {
                $el.off('.featured').on({
                    'filter.featured': (e, category, search) => {
                        e.preventDefault();
                        this.filter(category, search);
                    }
                });

                if (selected) {
                    this.filter(selected);
                }

                clearTimeout(this.cache.timer4);
                this.cache.timer4 = window.setTimeout(() => {
                    this.cache.$items.addClass(getCssClass(sel.isInitialized));
                    $el.removeClass(getCssClass(sel.isLoading));

                    callFn(callback);
                }, delay);

                return this;
            })
            .catch(function (reason) {
                !isProduction() && console.warn(reason); // eslint-disable-line no-console
            });
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link Featured}
 * @param {Object} [options] - The optional plugin options
 * @param {Function} [callback=noop] - The optional plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Featured(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
