import { isWebkitBrowser } from './client';

const noop = Function.prototype;

/**
 * Load image dynamically to listen for loaded events.
 *
 * @param {string} url - The image url
 * @param {Function} [callback=noop] - The callback function after success
 * @returns {void}
 */
function loadImage(url, callback = noop) {
    const image = new Image();

    image.onerror = function () {
        image.onload = image.onerror = null;
        callback(null);
    };

    image.onload = function () {
        image.onload = image.onerror = null;

        // IE fix for lazy src, without setTimeout the dimensions could be wrong
        setTimeout(function () {
            callback({
                height: image.height,
                width: image.width
            });
        });
    };

    /**
     * For webkit browsers, the following line ensures load event fires if
     * image src is the same as last image src. This is done by setting
     * the src to an empty string initially.
     *
     * @see Supercharged JavaScript Graphics (O'Reilly, page 83)
     */
    if (isWebkitBrowser()) {
        image.src = '';
    }

    // Set image source after the event handler is attached
    image.src = url;

    /**
     * Check if image is cached, trigger load manually. If onload
     * is already fired, avoid undefined errors.
     *
     * @see {@link http://github.com/desandro/imagesloaded}
     * @see {@link http://www.html5rocks.com/en/tutorials/es6/promises}
     */
    if (!!image.complete && image.naturalWidth !== undefined && image.onload) {
        image.onload();
    }
}

export {
    loadImage
};
