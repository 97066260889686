/**
 * Helper function to check jQuery element length.
 *
 * @param {jQuery} $element - The jquery element to check
 * @returns {boolean} Whether the element is present in dom or not
 */
function hasLength($element) {
    return $element && $element.length;
}

export default hasLength;
