/* global window */
const requestAnimationFrame = window && window.requestAnimationFrame;

/**
 * Request tick, make sure raf is just called when needed.
 *
 * This is an important optimization, since the browser will stack all the repeated rAF requests
 * and we would be back to a situation with more calls to update functions than we need.
 * Following the lazy loading design pattern, the function will be
 * overridden with the correct implemation the first time it will be
 * called. After that all consequent calls deliver the correct one without
 * conditions for different browsers.
 * 
 * @see {@link http://www.html5rocks.com/en/tutorials/speed/animations/}
 * @see nicolas c. zakas - maintainable javascript, writing readable code (o'reilly s.88)
 *
 * @param {Function} fn - The function to call via raf
 * @param {boolean} frame - The current tick check var
 * @param {Object} [element] - The optional dom element which contains the animation
 * @returns {number} The requestAnimationFrame id, useful for cancelAnimationFrame()
 */
let requestFrame = function (fn, frame, element) {
    if (requestAnimationFrame) {
        requestFrame = function (fnRaf, frameRaf, elementRaf) {
            let requestId;

            if (!frameRaf) {
                requestId = requestAnimationFrame(fnRaf, elementRaf);
                frameRaf = true;
            }

            return requestId;
        };
    } else {
        requestFrame = function (fnTimeout) {
            return setTimeout(fnTimeout, 0);
        };
    }

    return requestFrame(fn, frame, element);
};

export default requestFrame;