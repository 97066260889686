/*global google*/
/**
 * jQuery plugin for displaying a google map with
 * custom markers.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @example <caption>Basic plugin usage</caption>
 * $('.map').map({}, function () {
 *    console.log("ready");
 * });
 *
 * @requires jquery
 * @requires lodash
 * @requires utils/environment
 * @requires utils/function
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */
import $ from 'jquery';
import { get } from 'lodash';
import { isProduction } from '../utils/environment';
import { callFn } from '../utils/function';

const PLUGIN_NAME = 'map';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '3.0.0';

const baseUrl = window.likearchitecture && window.likearchitecture.baseUrl || ''; // eslint-disable-line no-mixed-operators 
const noop = Function.prototype;

/**
 * @private
 * @type {Object}
 * @property {Object} sel - Css selector config
 */
const DEFAULTS = {
    sel: {
        googleMapApi: '#google-map-api',
        mapCanvas: '#m-map__canvas',
        mapLoading: '.m-map__loading',
        mapMarker: '.m-map__marker',
        mapMarkerName: '.m-map__marker-name',
        mapMarkerLat: '.m-map__marker-lat',
        mapMarkerLng: '.m-map__marker-lng'
    },
    key: 'AIzaSyBT9ZYVMxnVt15wHmbKfTEUh87rtVHe3Ds',
    markers: {
        url: `${baseUrl}/assets/img/map/map-marker.svg`,
        size: [63, 80]
    },
    map: {
        zoom: 7,
        zoomIcon: 15,
        latlngStd: {
            lat: 52.2223996,
            lng: 12.8018186
        },
        styles: [
            {
                featureType: 'all',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                        visibility: 'simplified'
                    }
                ]
            }
        ]
    },
    geolocation: {
        enableHighAccuracy: false,
        timeout: 500,
        maximumAge: 0
    },
    onMarkerClick: noop
};

/**
 * Global callback when google maps api is loaded.
 *
 * @global
 * @param {Function} [callback] - The function to be called after load
 * @returns {void}
 */
window.googleApiLoaded = function googleApiLoaded(callback) {
    callFn(callback);
};

/**
 * Check if html5 watchposition is available.
 *
 * @private
 * @returns {boolean}
 */
function hasWatchPosition() {
    return get(navigator, 'geolocation.watchPosition');
}

/**
 * Load google maps javascript api.
 *
 * @private
 * @param {string} id - The script id attribute
 * @param {string} key - Google maps api key (Google API Console)
 * @returns {void}
 */
function loadApi (id, key) {
    if ($(`#${id}`).length) {
        return;
    }

    const script = document.createElement('script');
    const headNode = document.getElementsByTagName('head')[0];
    const firstScript = document.getElementsByTagName('script')[0];

    script.async = true;
    script.id = id;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=googleApiLoaded`;

    if (firstScript) {
        firstScript.parentNode.insertBefore(script, firstScript);
    } else {
        headNode.appendChild(script);
    }
}

/**
 * @class
 */
class Map {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     * @returns {void}
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            { callback }
        );

        this.version = VERSION;
        this.cache = {
            $el,
            opts,
            map: null,
            mapMarkers: []
        };

        this.googleApiLoaded = this.googleApiLoaded.bind(this);
        window.googleApiLoaded = window.googleApiLoaded.bind(this, this.googleApiLoaded);

        this.init();
    }

    /**
     * Create new html marker to display svg icons with custom markup.
     *
     * @see {@link http://stackoverflow.com/questions/29196855/google-maps-multiple-custom-html-markers}
     * @see {@link https://developers.google.com/maps/documentation/javascript/examples/overlay-simple?csw=1}
     *
     * @param {number} latitude - Marker lat value
     * @param {numer} longitude - Marker lng value
     * @returns {Object} The newly created HTMLMarker
     */
    htmlMarker(latitude, longitude) {

        /**
         * @private
         * @augments google.maps.OverlayView
         * @param {number} lat - Initial latitude value
         * @param {number} lng - Initial longitude value
         * @returns {void}
         */
        function HTMLMarker(lat, lng){
            this.lat = lat;
            this.lng = lng;
            this.pos = new google.maps.LatLng(lat, lng);
        }
    
        /** @inheritdoc */
        HTMLMarker.prototype = new google.maps.OverlayView();
        HTMLMarker.prototype.onRemove = function () {};

        HTMLMarker.prototype.setPos = function (lat, lng) {
            this.lat = lat;
            this.lng = lng;
            this.pos = new google.maps.LatLng(lat, lng);
        };

        HTMLMarker.prototype.onAdd = function () {
            this.div = document.createElement('div');
            this.div.className = 'html-marker';
            this.div.style.position = 'absolute';
            this.div.innerHTML = '<div class="simple"></div>';

            const panes = this.getPanes();
            panes.overlayImage.appendChild(this.div);
        };
        
        HTMLMarker.prototype.draw = function () {
            const overlayProjection = this.getProjection();

            if (overlayProjection && this.div) {
                const position = overlayProjection.fromLatLngToDivPixel(this.pos);
                this.div.style.left = position.x + 'px';
                this.div.style.top = position.y + 'px';
            }
            this.getPanes();
        };

        return new HTMLMarker(latitude, longitude);
    }

    /**
     * Parse latitude and longitude from html source. 
     *
     * @returns {void}
     */
    parseMarkers() {
        const { opts: { markers, sel } } = this.cache;
        let locations = [];

        $(sel.mapMarker).each(function () {
            const $this = $(this);

            locations.push([
                $this.find(sel.mapMarkerName).text(),
                $this.find(sel.mapMarkerLat).text(),
                $this.find(sel.mapMarkerLng).text()
            ]);
        });

        this.cache.markers = Object.assign(
            {},
            markers,
            { locations }
        );
    }

    /**
     * Setup map.
     *
     * @returns {void}
     */
    setupMap() {
        const { opts: { map, sel } } = this.cache;
        const { latlngStd, styles, zoom } = map;
        const mapCanvas = document.getElementById(sel.mapCanvas.substr(1));
        const MAP_STYLE = 'map_style';

        if (!mapCanvas) {
            return;
        }

        const googleMapsApi = google.maps;
        const styledMap = new googleMapsApi.StyledMapType(
            styles,
            {
                name: 'Styled Map'
            }
        );
        const googleMap = new googleMapsApi.Map(
            mapCanvas,
            {
                center: new googleMapsApi.LatLng(latlngStd.lat, latlngStd.lng),
                zoom: zoom,
                mapTypeControlOptions: {
                    mapTypeIds: [googleMapsApi.MapTypeId.ROADMAP, MAP_STYLE]
                }
            }
        );

        googleMap.mapTypes.set(MAP_STYLE, styledMap);
        googleMap.setMapTypeId(MAP_STYLE);

        this.cache = Object.assign(
            {},
            this.cache,
            {
                map: googleMap
            }
        );

        $(sel.mapLoading).hide();
    }

    /**
     * Create images, handle marker events and add locations to markers.
     *
     * @returns {void}
     */
    setupMarkers() {
        const { markers, map, opts } = this.cache;
        const { locations, url, size } = markers;
        const { zoom, zoomIcon } = opts.map;

        const image = {
            url: url,
            scaledSize: new google.maps.Size(size[0], size[1]),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(size[0] / 2, size[1])
        };

        const mapMarkers = locations.reduce(function (acc, location) {
            const marker = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                icon: image,
                map: map,
                optimized: false,
                position: {
                    lat: Number(location[1]),
                    lng: Number(location[2])
                },
                title: location[0],
                zIndex: location[3]
            });
            let clickOdd = true;

            marker.addListener('click', function () {
                const position = marker.getPosition();

                map.setZoom(clickOdd ? zoomIcon : zoom);
                map.setCenter(position);
                opts.onMarkerClick(position);

                clickOdd = !clickOdd;
            });

            acc.push(marker);
            return acc;
        }, []);

        this.cache = Object.assign(
            {},
            this.cache,
            {
                mapMarkers: mapMarkers
            }
        );
    }

    /**
     * Watch user position and draw marker.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/Using_geolocation}
     * @returns {void}
     */
    watchPosition() {
        const { map } = this.cache;
        let htmlMarker;
 
        if (!hasWatchPosition()) {
            return;
        }

        /**
         * @private
         * @param {Object} coords - Containing latitude and longitude
         * @returns {void}
         */
        const getMarker = (coords) => {
            if (!htmlMarker) {
                htmlMarker = this.htmlMarker(Number(coords.latitude), Number(coords.longitude));
                htmlMarker.setMap(map);
            }
            htmlMarker.setPos(
                Number(coords.latitude),
                Number(coords.longitude)
            );
            return htmlMarker;
        };

        /**
         * @private
         * @param {Object} pos - Current position description
         * @returns {void}
         */
        const success = (pos) => {
            if (!map) {
                navigator.geolocation.clearWatch(this.cache.watchPosition);
                return;
            }
            const coords = pos.coords;
            const marker = getMarker(coords);
            marker.draw();

            this.cache.locationMarker = marker;
        };

        /**
         * @private
         * @param {Object} err - Containing deeper error information
         * @returns {void}
         */
        const error = (err) => {
            navigator.geolocation.clearWatch(this.cache.watchPosition);
            !isProduction() && console.warn('ERROR(' + err.code + '): ' + err.message); // eslint-disable-line no-console
        };

        this.cache.watchPosition = navigator.geolocation.getCurrentPosition(success, error);
    }

    /**
     * Setup map.
     *
     * @returns {void}
     */
    googleApiLoaded() {
        const { opts: { callback } } = this.cache;

        this.parseMarkers();
        this.setupMap();
        this.setupMarkers();
        this.watchPosition();

        callFn(callback);
    }

    /**
     * Init plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const { opts: { key, sel } } = this.cache;

        if ($(sel.googleMapApi).length && typeof google !== 'undefined') {
            this.googleApiLoaded();
            return;
        }
        loadApi(sel.googleMapApi.substr(1), key);
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link Map}
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback=noop] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Map(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
