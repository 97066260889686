/**
 * jQuery plugin for entering and leaving the html5
 * fullscreen mode.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.2.0
 *
 * @example <caption>Basic plugin usage</caption>
 * $('.btn').fullscreen({ isFullscreen: '.is-fullscreen' }, function () {
 *    console.log('ready');
 * });
 *
 * @see {@link https://davidwalsh.name/fullscreen}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API}
 *
 * @requires jquery
 * @requires lodash
 * @requires utils/function
 *  @requires utils/get-css-class
 *
 * @changelog
 * - 0.2.0 Moved code to es6
 * - 0.1.2 Added namespace interface
 * - 0.1.1 Refactoring
 * - 0.0.1 Basic functions
 */
import $ from 'jquery';
import { isFunction } from 'lodash';
import { callFn } from '../utils/function';
import { getCssClass } from '../utils/get-css-class';

const PLUGIN_NAME = 'fullscreen';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.2.0';

const noop = Function.prototype;
const $doc = $(document);
const docElm = document && document.documentElement;

/**
 * @private
 * @type {Object}
 */
const DEFAULTS = {
    sel: {
        btn: '.btn--fullscreen',
        isFullscreenActive: '.is-fullscreen-active',
        isLoading: '.is-loading'
    }
};

/**
 * Check fullscreen browser support.
 * Following the lazy loading design pattern, the current function will be
 * overridden with the correct implementation the first time it will be
 * called. After that all consequent calls deliver the correct one without
 * conditions for different browser capabilities.
 *
 * @see Nicholas C. Zakas, High Performance JavaScript (Page 154)
 * 
 * @private
 * @returns {boolean} Whether fullscreen is supported or not
 */
/* eslint-disable-next-line one-var */
let isSupported = function () {
    const isSupportedInCurrentBrowser = !!docElm && (
        isFunction(docElm.requestFullscreen) ||
        isFunction(docElm.mozRequestFullScreen) ||
        isFunction(docElm.webkitRequestFullScreen) ||
        isFunction(docElm.msRequestFullscreen)
    );

    isSupported = function () {
        return isSupportedInCurrentBrowser;
    };
    
    return isSupported();
};

/**
 * Enter browser fullscreen mode. We need the document.documentElement here
 * to enter the fullscreen - don't be confused when locking at exit().
 *
 * Following the lazy loading design pattern, the current function will be
 * overridden with the correct implementation the first time it will be
 * called. After that all consequent calls deliver the correct one without
 * conditions for different browser capabilities.
 *
 * @see Nicholas C. Zakas, High Performance JavaScript (Page 154)
 *
 * @private
 * @param {Object} [element=docElm] - The node which requested the fullscreen
 * @returns {void}
 */
/* eslint-disable-next-line one-var */
let enter = function (element = docElm) {
    // We are not in a browser world
    if (!element) {
        enter = noop;
        return enter();
    }

    // Choose implementation based on browser features
    if (isFunction(element.requestFullscreen)) {
        enter = function () {
            return element.requestFullscreen();
        };
    }
    if (isFunction(element.mozRequestFullScreen)) {
        enter = function () {
            return element.mozRequestFullScreen();
        };
    }
    if (isFunction(element.webkitRequestFullScreen)) {
        enter = function () {
            return element.webkitRequestFullScreen();
        };
    }
    if (isFunction(element.msRequestFullscreen)) {
        enter = function () {
            return element.msRequestFullscreen();
        };
    }

    return enter();
};


/**
 * Exit browser fullscreen mode. Note that exitFullscreen is called on the
 * document object only - not needing to pass the individual element itself.
 * Following the lazy loading design pattern, the current function will be
 * overridden with the correct implementation the first time it will be
 * called. After that all consequent calls deliver the correct one without
 * conditions for different browser capabilities.
 *
 * @see Nicholas C. Zakas, High Performance JavaScript (Page 154)
 *
 * @private
 * @returns {void}
 */
/* eslint-disable-next-line one-var */
let exit = function () {
    // We are not in a browser world
    if (!document) {
        exit = noop;
        return exit();
    }

    // Choose implementation based on browser features
    if (isFunction(document.exitFullscreen)) {
        exit = function () {
            return document.exitFullscreen();
        };
    }
    if (isFunction(document.mozCancelFullScreen)) {
        exit = function () {
            return document.mozCancelFullScreen();
        };
    }
    if (isFunction(document.webkitCancelFullScreen)) {
        exit = function () {
            return document.webkitCancelFullScreen();
        };
    }
    if (isFunction(document.msExitFullscreen)) {
        exit = function () {
            return document.msExitFullscreen();
        };
    }

    return exit();
};

/**
 * Check if fullscreen is currently active.
 *
 * @private
 * @returns {boolean} Whether fullscreen is active or not
 */
function isFullscreenActive() {
    return !!document && (
        !!document.fullScreen || !!document.mozFullScreen || !!document.webkitIsFullScreen
    );
}

/**
 * @class
 */
class Fullscreen {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            { callback }
        );

        this.version = VERSION;
        this.cache = {
            $el,
            opts
        };
        this.isSupported = isSupported();
        this.isActive = false;

        this.updateUi = this.updateUi.bind(this);
        this.init();
    }

    /**
     * Check if we are in fullscreen mode, due to browser prefixes we
     * need to add some internal checks here.
     *
     * @returns {boolean} The current fullscreen active state
     */
    isFullscreenActive() {
        if (!this.isSupported) {
            return false;
        }

        // Because ms does not have this api (prefixed) we check additionally the
        // internal isActive state just be be safe
        const isActive = isFullscreenActive();

        return isActive !== undefined
            ? isActive
            : this.isActive;
    }

    /**
     * Updates ui button classNames based on current fullscreen
     * is active state.
     *
     * @returns {void}
     */
    updateUi() {
        const { $el, opts: { sel } } = this.cache;
        const isFullscreen = getCssClass(sel.isFullscreenActive);

        if (this.isFullscreenActive()) {
            $el.addClass(isFullscreen);
        } else {
            $el.removeClass(isFullscreen);
        }
    }

    /**
     * Toggle fullscreen mode.
     *
     * @returns {void}
     */
    toggle() {
        if (this.isFullscreenActive()) {
            exit();
            this.isActive = false;
        } else {
            enter();
            this.isActive = true;
        }
    }

    /**
     * Init plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const { $el, opts: { callback, sel } } = this.cache;

        if (this.isSupported) {
            $el.removeClass(getCssClass(sel.isLoading));
        }

        $doc.off('.fullscreen').on({
            'fullscreenchange.fullscreen': this.updateUi,
            'mozfullscreenchange.fullscreen': this.updateUi,
            'webkitfullscreenchange.fullscreen': this.updateUi
        });

        $el.off('.fullscreen').on({
            'click.fullscreen': (event) => {
                event && event.preventDefault();
                this.toggle();
            }
        });

        this.updateUi();

        callFn(callback);
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link Fullscreen}
 * @param {Object} [options] - The optional plugin options
 * @param {Function} [callback=noop] - The optional plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(
                this,
                PLUGIN_DATA_STRING,
                new Fullscreen(this, options, callback.bind(this))
            );
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
