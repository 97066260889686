/**
 * Check if element is visible.
 *
 * @param {jQuery} $element - The jquery element to check
 * @returns {boolean} Whether the element is visible or not
 */
function isVisible($element) {
    return $element && $element.is(':visible');
}

export default isVisible;