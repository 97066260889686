import { createStore, combineReducers } from 'redux';
import { loadState, saveState } from './cache-store';
import { reducerCookie } from './cookie/duck';
import { reducerFeatured } from './featured/duck';

/**
 * Creating the single application store.
 *
 * The combineReducers helper function turns an object whose values are
 * different reducing functions into a single reducing function you can
 * pass to createStore.
 *
 * The resulting reducer calls every child reducer, and gathers their
 * results into a single state object. The shape of the state object matches
 * the keys of the passed reducers.
 *
 * @private
 */
const reducers = combineReducers(Object.assign(
    {},
    reducerCookie,
    reducerFeatured
));

/**
 * Creates a Redux store that holds the complete state tree of the app.
 * There should only be a single store in your app.
 *
 * @param {Object} [preloadedState={}] - Initial store config to reduce the payload on load
 * @returns {Object} The newly created store
 */
function configureStore(preloadedState = {}) {
    // Create store with cached data
    const store = createStore(
        reducers,
        Object.assign(
            {},
            preloadedState,
            loadState()
        )
    );

    // Listen to changes to save state in cache
    store.subscribe(() => {
        saveState(store.getState());
    });

    return store;
}

export {
    configureStore
};