/**
 * jQuery plugin for button and link border animations.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @example <caption>Basic plugin usage</caption>
 * $('.border').border({ delay: 50 }, function () {
 *    console.log("ready");
 * });
 *
 * @requires jquery
 * @requires utils/get-css-class
 * @requires utils/function
 * @requires utils/move
 *
 * @TODO:
 * - Use selector plugin input instead of headcoded sel.items for targets
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */
import $ from 'jquery';
import { getCssClass } from '../utils/get-css-class';
import { callFn } from '../utils/function';
import {
    isMoveX,
    isMoveY
} from './../utils/move';

const PLUGIN_NAME = 'border';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.0.1';

const DATA_ENTERED_KEY = 'entered';
const DATA_ID_KEY = 'id';

const $document = $(document);

/**
 * @private
 * @type {Object}
 * @property {Object} sel - Css selector config
 * @property {string} sel.bottom - ClassName to be aligned for bottom entries
 * @property {string} sel.items - Selector for the children to be enhanced
 * @property {string} sel.left - ClassName to be aligned for left entries
 * @property {string} sel.right - ClassName to be aligned for right entries
 * @property {string} sel.top - ClassName to be aligned for top entries
 * @property {Object} mouseMoves - Mouse handling config
 * @property {number} mouseMoves.maxTracked - Number of past mouse locations to track
 * @property {number} delay - Animation delay for adding/removing classNames
 */
const DEFAULTS = {
    sel: {
        bottom: '.align-bottom',
        items: '.c-border, .m-featured__link',
        left: '.align-left',
        right: '.align-right',
        top: '.align-top'
    },
    mouseMoves: {
        maxTracked: 10
    },
    delay: 200
};

/**
 * jQuery animate border plugin.
 */
class Border {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     * @returns {void}
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            callback && { callback }
        );

        this.version = VERSION;
        this.store = {
            $el,
            opts,
            mouseMoves: [],
            timers: []
        };

        this.getDirection = this.getDirection.bind(this);
        this.init();
    }

    /**
     * Track mouse movement on the page.
     *
     * @param {Object} e - The event object
     * @returns {void}
     */
    onMouseMove(e) {
        const { mouseMoves, opts } = this.store;

        mouseMoves.push({
            x: e.pageX,
            y: e.pageY
        });

        if (mouseMoves.length > opts.mouseMoves.maxTracked) {
            mouseMoves.shift();
        }

        this.store.mouseMoves = mouseMoves;
    }

    /**
     * Get current direction based on mouse movement.
     *
     * @see {@link https://github.com/kamens/jQuery-menu-aim/blob/master/jquery.menu-aim.js}
     *
     * @param {number} factor - Positiv or negativ calculation factor for direction changes
     * @returns {string} ClassName based on event direction 
     */
    getDirection(factor = 1) {
        const { mouseMoves, opts: { sel } } = this.store;

        const lastMouseMove = mouseMoves[mouseMoves.length - 1] || { x: 0, y: 0};
        const firstMouseMove = mouseMoves[0] || lastMouseMove;
        const distX = lastMouseMove.x - firstMouseMove.x;
        const distY = lastMouseMove.y - firstMouseMove.y;
        const distXAbs = Math.abs(distX);
        const distYAbs = Math.abs(distY);

        if (distXAbs > distYAbs && isMoveX({ distX, distY })) {
            const direction = (distX * factor) > 0
                ? getCssClass(sel.left)
                : getCssClass(sel.right);
            return direction;
        }
        if (distXAbs < distYAbs && isMoveY({ distX, distY })) {
            const direction = (distY * factor) > 0
                ? getCssClass(sel.bottom)
                : getCssClass(sel.top);
            return direction;
        }
    }

    /**
     * Setup plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const { timers, opts: { callback, delay, sel } } = this.store;
        const getDirection = this.getDirection;
        const allDirectionClassNames = [
            getCssClass(sel.top),
            getCssClass(sel.right),
            getCssClass(sel.bottom),
            getCssClass(sel.left)
        ].filter(Boolean).join(' ');

        // Track mouse movements and keyboard
        $document.off('.border').on({
            'mousemove.border': (event) => {
                this.onMouseMove(event);
            }
        });

        $(sel.items)
            .each(function () {
                const $this = $(this);

                // Generate unique id based on position
                const { top, left } = $this.offset();
                $this.data(DATA_ID_KEY, `${top}-${left}`);
            })
            .off('.border')
            .on({
                'mouseenter.border': function () {
                    const $this = $(this);
                    const timerId = $this.data(DATA_ID_KEY);

                    clearTimeout(timers[timerId]);
                    $this
                        .data(DATA_ENTERED_KEY, true)
                        .removeClass(allDirectionClassNames)
                        .addClass(getDirection());
                },
                'mousemove.border': function () {
                    const $this = $(this);
                    const timerId = $this.data(DATA_ID_KEY);

                    if ($this.data(DATA_ENTERED_KEY)) {
                        timers[timerId] = setTimeout(function () {
                            $this.data(DATA_ENTERED_KEY, false);
                        }, delay);
                    } else {
                        clearTimeout(timers[timerId]);
                        $this
                            .removeClass(allDirectionClassNames)
                            .addClass(getDirection(-1));
                    }
                },
                'mouseleave.border': function () {
                    const $this = $(this);
                    const timerId = $this.data(DATA_ID_KEY);

                    if ($this.data(DATA_ENTERED_KEY)) {
                        $this
                            .removeClass(allDirectionClassNames)
                            .addClass(getDirection(-1));
                    }

                    $this.data(DATA_ENTERED_KEY, false);
                    clearTimeout(timers[timerId]);
                    timers[timerId] = setTimeout(function () {
                        $this.removeClass(allDirectionClassNames);
                    }, delay);
                }
            });

        callFn(callback);
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 * 
 * @see {@link Border}
 *
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function plugin(options, callback) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Border(this, options, callback));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
