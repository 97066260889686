/* eslint-disable immutable/no-let */
/* global performance, requestAnimationFrame*/
/**
 * Es6 module for helper component.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @see {@link http://stackoverflow.com/questions/8917921/cross-browser-javascript-not-jquery-scroll-to-top-animation}
 * @see {@link https://github.com/danro/easing-js/blob/master/easing.js}
 * @see {@link  https://github.com/cferdinandi/smooth-scroll/blob/master/src/js/smooth-scroll.js}
 *
 * @requires lodash
 * @requires common/utils/environment
 *
 * @changelog
 * - 0.0.1 basic functions and structure
 */
import {
    animate
} from './animate';
import {
    callFn
} from './function';

const noop = Function.prototype;

/**
 * Helper function to get scrolling offset.
 *
 * 'document.body.scrollTop' was working in Chrome but didn't work on Firefox, so had to resort to window.pageYOffset.
 * But can't fallback to document.body.scrollTop as that doesn't work in IE with a doctype (?) so have to use
 * document.documentElement.scrollTop
 *
 * @see {@link https://github.com/yuanyan/react-image/blob/master/src/Image.js}
 *
 * @function
 * @private
 * @returns {number} The current scrolling y offset value
 */
function getPageOffset() {
    const currentScrollY = window.pageYOffset || window.scrollY || document.documentElement.scrollTop || 0;

    /**
     * Could be negative while bouncing, so we restrict the value
     * here to avoid errors
     */
    return currentScrollY < 0
        ? 0
        : currentScrollY;
}

/**
 * Animate window scroll position.
 *
 * @function
 * @param {Object} [opts={}] - The scrolling options
 * @param {number} [opts.top=0] - The window scroll top position
 * @param {number} [opts.duration=300] - The scrolling animation time
 * @param {Function} [opts.easing=easeInOutQuad] - The animation easing function
 * @param {Function} [opts.callback=noop] - The animation done callback
 * @returns {void}
 */
function scrollTo(opts = {}) {
    const options = Object.assign({}, {
        top: 0,
        duration: 300,
        callback: noop
    }, opts);

    if (!options.duration) {
        window.scrollTo(0, options.top);
        callFn(options.callback);
        return;
    }

    const scrollTopCurrent = getPageOffset();
    animate(
        function stepFunction(time) {
            window.scrollTo(0, Math.floor(scrollTopCurrent + ((options.top - scrollTopCurrent) * time)));
        },
        options.callback,
        options.duration
    );
}

export default scrollTo;
export {
    getPageOffset
};
