/**
 * Es6 module for helper component.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @see {@link http://josdejong.com/blog/2015/03/28/a-broader-view-on-isomorphic-javascript/}
 * @see {@link https://github.com/sospedra/logatim/blob/master/lib/utils.js}
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */
import { get } from 'lodash';
import {
    DEVELOPMENT,
    PRODUCTION
} from '../constants/environment';

/**
 * Check if current environment is browser.
 *
 * @returns {boolean}
 */
function isBrowser() {
    return typeof window !== 'undefined';
}

/**
 * Check if current environment is node.
 *
 * @returns {boolean}
 */
function isNode() {
    return typeof window === 'undefined' && typeof process !== 'undefined';
}

/**
 * Check if we have a production build.
 *
 * @param {string} [env='development'] - The environment to be checked
 * @returns {boolean} 
 */
function isProduction(env = DEVELOPMENT) {
    const nodeEnv = get(process, 'env.NODE_ENV', env);

    return nodeEnv === PRODUCTION;
}

export {
    isBrowser,
    isNode,
    isProduction
};
