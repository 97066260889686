

const VENDOR_PREFIXES = [
    { js: '', css: '' },
    { js: 'O', css: '-o-' },
    { js: 'ms', css: '-ms-' },
    { js: 'Moz', css: '-moz-' },
    { js: 'webkit', css: '-webkit-' }
];

let css3PrefixesFilterd = [];

/**
 * Capitalize the first letter.
 *
 * @private
 * @see {@link http://stackoverflow.com/questions/1026069/capitalize-the-first-letter-of-string-in-javascript}
 * @param {string} string - The text to be transformed
 * @returns {string|*} The capitalised string
 */
function capitalise(string) {
    if (!string) {
        return string;
    }
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

/**
 * Test css property in current browser.
 *
 * @param {string} name - The css property
 * @returns {Array} A list of supported prefixes.
 */
function testCssProperty(name) {
    if (!VENDOR_PREFIXES.length) {
        return [];
    }

    const testElement = document.createElement('div');

    return VENDOR_PREFIXES.reduce(function (acc, prefix) {
        const jsPrefix = prefix.js;
        const cssProperty = jsPrefix
            ? jsPrefix + capitalise(name)
            : name;

        if (testElement.style[cssProperty] !== undefined) {
            acc.push(prefix.css);
        }
        return acc;
    }, []);
}
const cssTransform = 'transform';
const hasCssTransform = testCssProperty(cssTransform);

/**
 * Get filterd css vendor prefixes.
 *
 * @see {@link http://stackoverflow.com/questions/708895/how-to-set-the-style-webkit-transform-dynamically-using-javascript}
 *
 * @private
 * @param {string} name - The css property
 * @returns {Array} The filted css property list
 */
function getCss3PrefixesFilterd(name) {
    if (css3PrefixesFilterd[name]) {
        return css3PrefixesFilterd[name];
    }

    const properties = testCssProperty(name);
    if (properties.length) {
        css3PrefixesFilterd[name] = properties;
    }

    return css3PrefixesFilterd[name];
}

/**
 * Get css3 transform object with supported vendor prefixes.
 *
 * @private
 * @param {string} value - The transform value
 * @returns {Object}
 */
function getCss3TransformObject(value) {
    const prefixes = getCss3PrefixesFilterd(cssTransform);

    return prefixes.reduce(function (acc, prefix) {
        return Object.assign(
            acc,
            {
                [prefix + cssTransform]: value
            }
        );
    }, {});
}

/**
 * Get css3 translate value.
 *
 * @private
 * @param {number} x - The x position
 * @param {number} y - The y position
 * @returns {string} The css3 translate value
 */
function getCss3TranslateValue(x, y) {
    const cssTransforms3d = false;

    return 'translate' + (cssTransforms3d
        ? '3d(' + x + 'px,'
        : '(' + x + 'px,'
    ) + y + 'px' + (cssTransforms3d
        ? ',0)'
        : ')'
    );
}

/**
 * Get css3 position object.
 *
 * @private
 * @param {number} x - The x position
 * @param {number} y - The y position
 * @returns {Object} The css3 position object
 */
function getCss3PositionObject(x, y) {
    return getCss3TransformObject(getCss3TranslateValue(x, y));
}

/**
 * @param {string|number} x - The x position
 * @param {string|number} y - The y position
 * @returns {Object} The css position object
 */
function getCssPositionObject(x, y) { 
    if (hasCssTransform) {
        return getCss3PositionObject(
            Number(x),
            Number(y)
        );
    } 
    return {
        'left': Number(x) + 'px',
        'top': Number(y) + 'px'
    };
}


export {
    getCssPositionObject
};
