import $ from 'jquery';
import { getCssClass } from '../../utils/get-css-class';

const MathAbs = Math.abs;
const $emptyJqueryObject = $({});
const IMG = 'img';

/**
 * Get id value in given range (normalize offset).
 *
 * @private
 * @param {number} offset - The current offset
 * @param {number} length - The max value
 * @returns {number} The normalized position
 */
export function getIndex(offset, length) {
    const index = offset % length;

    if (index < 0) {
        return MathAbs(index + length);
    }

    return MathAbs(index);
}

/**
 * Check if given slide catches the edge (index is 0 or slidesLength).
 *
 * @private
 * @param {number} index - The current slide index
 * @param {number} slidesLength - The length of all slides
 * @returns {boolean} Whether the given index catches bounds or not
 */
export function isEdgeIndex(index, slidesLength) {
    return slidesLength > 2 && (index <= 0 || index >= slidesLength);
}

/**
 * Generate slides from given html content.
 *
 * @private
 * @param {jQuery} $childs - Collection of childs to be a added as slides
 * @param {Object} sel - The css selector config
 * @returns {Array<jQuery>} New generated slides to be added
 */
export function getSlidesArray($childs, sel) {
    return $childs.toArray().reduce(function (accumulator, current) {
        const $this = $(current);
        const $slide = $(`<${sel.slideTag}>`, {
            'tabindex': '-1'
        })
            .addClass(getCssClass(sel.slideSel))
            .append($this);
        accumulator.push($slide);

        return accumulator;
    }, []);
}

/**
 * Get image element in slide.
 *
 * @private
 * @param {Jquery} $slide - The slide where the image should be found
 * @returns {Jquery} The image within the slide
 */
export function getSlideImage($slide) {
    if (!$slide) {
        return $emptyJqueryObject;
    }

    return $slide.is(IMG)
        ? $slide
        : $slide.find(IMG);
}

/**
 * Get a factor for multiplication to calculate the scrolling direction's
 * distance based on animation direction.
 *
 * @private
 * @param {number} from - The scrolling from start point
 * @param {number} dest - The scrolling dest end point
 * @returns {number} 1 or -1 depending on direction
 */
export function getScrollDirectionFactor(from, dest) {
    return from <= dest
        ? 1
        : -1;
}
