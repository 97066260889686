/**
 * Helper function to trigger event on jquery element.
 *
 * @private
 * @param {jQuery} $element - The jquery element to check
 * @param {string} eventName - The event name to be triggered
 * @returns {void}
 */
function triggerEvent($element, eventName) {
    $element.trigger(eventName);
}

export default triggerEvent;
