/* eslint-disable no-mixed-operators */
/**
 * Es6 module for animating dom elements.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @requires lodash
 * @requires utils/function
 *
 * @changelog
 * - 0.0.1 Basic function and structure
 */
import { isFunction } from 'lodash';
import {
    callFn
} from './function';

const requestAnimationFrame = window.requestAnimationFrame;
const cancelAnimationFrame = window.cancelAnimationFrame;
const hasRequestAnimationFrame = requestAnimationFrame && cancelAnimationFrame;
const setTimeout = window.setTimeout;
const clearTimeout = window.clearTimeout;
const performance = window.performance;

/**
 * Get current time helper.
 * Following the lazy loading design pattern, the current function will be
 * overridden with the correct implementation the first time it will be
 * called. After that all consequent calls deliver the correct one without
 * conditions for different browser capabilities.
 *
 * @see Nicholas C. Zakas, High Performance JavaScript (Page 154)
 *
 * @private
 * @returns {number} The current timestamp
 */
let getTime = function () {
    if (!!performance && isFunction(performance.now)) {
        getTime = function () {
            return performance.now();
        };
    } else {
        getTime = function () {
            return (new Date()).getTime();
        };
    }

    return getTime();
};

/**
 * Quad easing function - acceleration until halfway, then deceleration.
 *
 * @see {@link https://gist.github.com/gre/1650294}
 *
 * @private
 * @param {number} t - The current easing parameter
 * @returns {number} The eased result
 */
function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

/**
 * Animate helper function.
 *
 * @see {@link https://github.com/gre/bezier-easing}
 *
 * @param {Function} render - The function to be rendered at each animation step
 * @param {Function} [callback=noop] - The optional callback after animation has finished
 * @param {number} [duration=200] - The duration time in milliseconds
 * @param {Function} [easing=easeInOutQuad] - The easing function
 * @returns {number} requestId The animation request id
 */
function animate(render, callback = Function.prototype, duration = 200, easing = easeInOutQuad) {
    const start = getTime();
    const animateFunction = hasRequestAnimationFrame
        ? requestAnimationFrame
        : setTimeout;
    let requestId = 0;

    (function loop() {
        const progress = (getTime() - start) / duration;

        if (progress > 1) {
            render(1);
            callFn(callback);
        } else {
            requestId = animateFunction(loop, 1000 / 30);
            render(easing(progress), requestId);
        }
    }());

    return requestId;
}

/**
 * Stop running animation.
 *
 * @see {@link http://stackoverflow.com/questions/10735922/how-to-stop-a-requestanimationframe-recursion-loop}
 *
 * @param {number} requestId - The animation request id
 * @returns {void}
 */
function stop(requestId) {
    if (!requestId) {
        return;
    }

    if (hasRequestAnimationFrame) {
        cancelAnimationFrame(requestId);
    } else {
        clearTimeout(requestId);
    }
}

export {
    animate,
    stop
};
