import picturefill from 'picturefill';

function initPicturefill() {

    // could be undefined e.g. in test files
    if (typeof picturefill === 'undefined') {
        return;
    }

    /**
     * For olders versions of picturefill the global pictureFill reference will be undefined for
     * browsers supporting <picture> natively. In newer versions (>= 3) pictureFill is exporting
     * a noop function to avoid checks for undefined.
     * Use setTimeout hack to make sure this runs after React Router's own listener.
     *
     * `picturefill` needs to be globally available here to make this work without errors
     * (is included in main client file).
     */
    setTimeout(() => {
        picturefill();
    });

}

export default initPicturefill;
