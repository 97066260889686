/**
 * Redux configuration for featured state handling.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @changelog
 * - 0.0.1 Basic function and structure
 */

/**
 * Action name for changing selected item.
 *
 * @type {string}
 */
export const CHANGE_FEATURED_SELECTED = 'CHANGE_FEATURED_SELECTED';

/**
 * Resource name for the store object.
 *
 * @type {string}
 */
export const FEATURED_RESOURCE_NAME = 'featured';

/**
 * Default state for the store.
 *
 * @type {Object}
 */
export const DEFAULT_STATE = {
    selected: ''
};

/**
 * Trigger selected feature selected state changes.
 *
 * @param {string} selected - The selected feature state
 * @returns {void}
 */
export function changeFeaturedSelected(selected) {
    return {
        type: CHANGE_FEATURED_SELECTED,
        selected
    };
}

/**
 * Handle selected feature selected state changes.
 *
 * @param {Object} state - The current redux state
 * @param {Object} action - The current action
 * @returns {Object} The (modified) redux state
 */
export function reducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
    case CHANGE_FEATURED_SELECTED:
        return {
            ...state,
            selected: action.selected
        };
    default:
        return state;
    }
}

/**
 * Convinient helper to export the reducer with
 * the corresponding redux store object key.
 *
 * @type {Object}
 */
export const reducerFeatured = {
    [FEATURED_RESOURCE_NAME]: reducer
};
