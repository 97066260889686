/**
 * jQuery plugin validate form input elements on change
 * and trigger corresponding errors.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @example <caption>Basic plugin usage</caption>
 *  $('form').validate();
 *
 * @requires jquery
 * @requires lodash
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */
import $ from 'jquery';
import { isFunction } from 'lodash';

const PLUGIN_NAME = 'validate';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.0.1';

const DEFAULTS = {
    sel: {
        validationtypesAttr: 'validationtypes',
        inputs: 'input, textarea, select',
        submit: 'input[type=submit], button[type=submit]',
        inputGroup: '.m-form__group',
        inputSmall: '.form__group--small',
        classError: '.has-error',
        classSuccess: '.has-success'
    },
    successDelay: 1000
};

const noop = Function.prototype;

/**
 * Handle validation by type.
 *
 * @private
 * @class
 */
class Validator {

    /**
     * The actual validator constructor.
     *
     * @returns {void}
     */
    constructor() {

        /**
         * @type {Object} All validation types
         */
        this.types = {
            required: {
                validate: function (value, $input) {
                    const tagName = $input[0].tagName.toLowerCase();

                    switch (tagName) {
                    case 'select':
                        return !!(value && value.length > 0);
                    case 'input':
                    case 'textarea':
                        return $.trim(value).length > 0;
                    default:
                        return $.trim(value) !== '';
                    }
                },
                instructions: 'This value is required'
            },
            number: {
                validate: function (value) {
                    return !isNaN(value);
                },
                instructions: 'Only numbers are allowed'
            },
            alpha: {
                validate: function (value) {
                    return !/[^a-z]/i.test(value);
                },
                instructions: 'Only chars are allowed'
            },
            alphaNum: {
                validate: function (value) {
                    return !/[^a-z0-9]/i.test(value);
                },
                instructions: 'Only chars and numbers are allowed'
            },
            email: {
                validate: function (value) {
                    // eslint-disable-next-line max-len, security/detect-unsafe-regex, no-control-regex, no-useless-escape
                    return (/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i).test(value);
                },
                instructions: 'This value needs a valid e-mail address'
            }
        };

        /**
         * @type {Array<string>} Container for all current error messages
         */
        this.messages = [];
    }

    /**
     * Validate by type.
     *
     * @param {string} type - The validation type
     * @param {jQuery} $input - A single input
     * @param {jQuery} $form - The whole form
     * @returns {boolean} The input validation status
     */
    validate (type, $input, $form) {
        this.messages = [];

        const value = $input.val();
        const checker = this.types[type];

        // No need to validate
        if (!type) {
            return true;
        }

        // Uh-oh there is an error
        if (!checker) {
            throw {
                name: 'ValidationError',
                message: 'No handler to validate type ' + type
            };
        }

        const resultOk = checker.validate(value, $input, $form);
        if (!resultOk) {
            const msg = 'Invalid value ' + checker.instructions;
            this.messages.push(msg);
        }

        return this.hasErrors();
    }

    /**
     * Helper function to check for current errors.
     *
     * @returns {boolean} The current validation status
     */
    hasErrors () {
        return this.messages.length !== 0;
    }

}

/**
 * jQuery google maps plugin.
 *
 * @class
 */
class Validate {

    /**
     * The actual stir plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     * @returns {void}
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            callback && { callback }
        );

        this.validator = new Validator();
        this.addSubmitFeedback = this.addSubmitFeedback.bind(this);
        this.addInputFeedback = this.addInputFeedback.bind(this);
        this.validateFormInput = this.validateFormInput.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.version = VERSION;
        this.store = {
            $el,
            opts
        };

        this.init();
    }

    /**
     * Add feedback to submit button.
     *
     * @param {boolean} validationSuccess - The current form validation status
     * @returns {void}
     */
    addSubmitFeedback(validationSuccess) {
        const {
            $el,
            $submit,
            $small,
            opts: { sel }
        } = this.store;
        const classError = sel.classError;
        let hasFormErrors = false;

        if (validationSuccess !== undefined) {
            hasFormErrors = !validationSuccess;
        } else {
            // @TODO: Should come from internal state instead of dom crawling
            hasFormErrors = !$el.find(classError).filter(function () {
                return !$(this).is('input[type=submit], button[type=submit]');
            }).length;
        }

        if (hasFormErrors) {
            $small.addClass(classError.substr(1));
            $submit.addClass(classError.substr(1));
        } else {
            $small.removeClass(classError.substr(1));
            $submit.removeClass(classError.substr(1));
        }
    }

    /**
     * Add feedback to single form input and label.
     *
     * @param {boolean} validationSuccess - The inputs validation state
     * @param {jQuery} $formInput - The jquery input element
     * @returns {void}
     */
    addInputFeedback(validationSuccess, $formInput) {
        if (validationSuccess === undefined || !$formInput || !$formInput.length) {
            return;
        }

        const { opts: { sel } } = this.store;
        const classError = sel.classError;
        const classSuccess = sel.classSuccess;

        const $formInputWrapper = $formInput.parents(sel.inputGroup);
        if (!validationSuccess) {
            $formInputWrapper.addClass(classError.substr(1));
        } else {
            $formInputWrapper
                .removeClass(classError.substr(1))
                .addClass(classSuccess.substr(1));
            setTimeout(function () {
                $formInputWrapper.removeClass(classSuccess.substr(1));
            }, 500);
        }
    }

    /**
     * Validate single form input.
     *
     * @param {jQuery} $formInput - The jquery input element
     * @returns {boolean} The validation state
     */
    validateFormInput($formInput) {
        const { $el, opts: { sel } } = this.store;

        // @TODO: This should come from an internal state rather than crawling the dom each time
        const validationtypesAttr = sel.validationtypesAttr;
        const validationTypes = $formInput.data(validationtypesAttr) 
            ? $formInput.data(validationtypesAttr).split(';') 
            : [];

        let validationSuccess = true;

        if (!validationTypes.length) {
            return validationSuccess;
        }

        validationTypes.forEach((validationType) => {
            this.validator.validate($.trim(validationType), $formInput, $el);
            if (this.validator.hasErrors()) {
                validationSuccess = false;
                // @TODO: break;?
            }
        });
        this.addInputFeedback(validationSuccess, $formInput);

        return validationSuccess;
    }

    /**
     * Validate complete from.
     *
     * @param {Object} e - The event object
     * @returns {void}
     */
    validateForm(e) {
        const { $inputs } = this.store;
        const validateFormInput = this.validateFormInput;
        let validationFormInputSuccess = true,
            validationSuccess = true;

        $inputs.each(function () {
            validationFormInputSuccess = validateFormInput($(this));
            if (!validationFormInputSuccess) {
                validationSuccess = false;
            }
        });

        this.addSubmitFeedback(validationSuccess);
        if (!validationSuccess) {
            e.preventDefault();
            return false;
        }
    }

    /**
     * Bind events to jquery elements.
     *
     * @returns {void}
     */
    events() {
        const { $el, $inputs } = this.store;
        const validateFormInput = this.validateFormInput;
        const addSubmitFeedback = this.addSubmitFeedback;

        // Validate form on submit, can't use namespacing here
        $el.submit(this.validateForm);

        // Validate form element after focus/ on blur
        $inputs.on({
            'blur.form change.form': function () {
                validateFormInput($(this));
                addSubmitFeedback();
            }
        });

        // Handle reset
        $el.find('input[type=reset]').on({
            'click.form': function () {
                return false;
            }
        });
    }

    /**
     * Init plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const { $el, opts } = this.store;

        const $inputs = $el.find(opts.sel.inputs);
        const $submit = $el.find(opts.sel.submit).first();
        const $small =  $el.find(opts.sel.inputSmall);

        this.store = Object.assign(
            {},
            this.store,
            {
                $inputs,
                $submit,
                $small
            }
        );
        this.events();

        // Remove HTML5 validation to avoid unexpected behaviour
        $inputs.removeAttr('required');
        $el.attr('novalidate', 'novalidate');

        if (isFunction(opts.callback)) {
            opts.callback();
        }
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 * 
 * @see {@link Validate}
 * @example <caption>Basic plugin usage</caption>
 *    $('.form').validate();
 *
 * @function
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback=noop] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Validate(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
