/**
 * Redux selector for featured state handling.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @requires reselect
 *
 * @changelog
 * - 0.0.1 Basic function and structure
 */
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { DEFAULT_STATE } from './duck';

/**
 * Select featured selected state of there are any changes.
 *
 * @param {Object} state - The current redux state
 * @returns {Object} The current featured selected state
 */
const selectFeaturedSelected = createSelector(
    (state) => state.featured,
    function (featured) {
        if (isEmpty(featured) || !featured.selected) {
            return DEFAULT_STATE.selected;
        }
        return featured.selected;
    }
);

export {
    selectFeaturedSelected
};