/**
 * Replace standard select input with styled input, trigger native select for mobile devices and provide an api via events.
 *
 * @name jQuery plugin styledSelect
 * @file
 *
 * @author
 * @version 4.0.0
 *
 * @requires jQuery
 * @requires lodash
 * @requires plugins/jquery.styled-select/
 *
 * @example <caption>Basic plugin usage</caption>
 *  // init plugin
 *  $('select').styledSelect();
 *
 *  // init plugin for all selects within form
 *  $('form').styledSelect();
 * 
 * @changelog
 * - 4.0.0 Moved code to es6
 * - 0.3.1 Added optgroup rendering (again)
 * - 0.3.0 Refactoring, api improvements, simplified code, added custom callback functions
 * - 0.2.7 Fix for windows mobile phones added
 * - 0.2.6 Moved helper functions to helpers.plugin
 * - 0.2.5 Bugfix for mobile if select is hidden via css, improved aria roles
 * - 0.2.4 ShowHeader option added, check if given jquery element is select element added, options parsing via html data-attribute
 * - 0.2.3 Aria roles added for accessibilty @see http://www.smashingmagazine.com/2014/05/21/mobile-accessibility-why-care-what-can-you-do/, http://www.smashingmagazine.com/2014/07/09/the-wai-forward/
 * - 0.2.2 Bug fix for firefox, bug fix for option tags positioned outside optgroups, added keyboard support
 * - 0.2.1 Refactoring, jslint
 * - 0.2.0 Complete rewrite with focus on select element events, which could be triggered from outside to provide a simple api
 * - 0.1.8.1 Bug fix ie9, performance bug fix
 * - 0.1.8 Refactoring
 * - 0.1.7 Handle blur/focus events for keyboard navigation
 * - 0.1.6 Refresh bug fix
 * - 0.1.5 Refresh event added
 * - 0.1.4 Refactoring, set first item in header if no option is selected on load, close others on open
 * - 0.1.3 Improved dom selectors, bug fixes
 * - 0.1.2 Removed touchclick
 * - 0.1.1 Bug fixes, refactoring
 * - 0.0.1 Basic functions
 *
 * @tbd:
 * - add optgroup css and tags to defaults
 */
import $ from 'jquery';
import { isFunction } from 'lodash';

import {
    callFn,
    getCssClass,
    guid,
    isMobileBrowser
} from './../utils';
import hasLength from './jquery.styled-select/has-length';
import isValidValue from './jquery.styled-select/is-valid-value';
import isVisible from './jquery.styled-select/is-visible';
import renderOptGroup from './jquery.styled-select/render-opt-group';
import renderOptions from './jquery.styled-select/render-options';
import triggerEvent from './jquery.styled-select/trigger-event';

const PLUGIN_NAME = 'styledSelect';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '4.0.0';

const DEFAULTS = {
    sel: {
        el: 'select',
        option: 'option',
        optgroup: 'optgroup',

        wrapperTag: 'div',
        wrapperId: '#v-styled-select',
        wrapperCss: '.v-styled-select',

        headerTag: 'div',
        headerId: '#v-styled-select__header',
        headerCss: '.v-styled-select__header',
        headerTextTag: 'a',
        headerTextCss: '.v-styled-select__header-text',
        headerIconTag: 'div',
        headerIconCss: '.v-styled-select__header-icon',

        contentTag: 'ul',
        contentId: '#v-styled-select__content',
        contentCss: '.v-styled-select__content',
        contentEntryTag: 'li',
        contentEntryId: '#v-styled-select__content-entry',
        contentEntryCss: '.v-styled-select__content-entry',

        hasError: '.has-error',
        hasSuccess: '.has-success',
        hasFocus: '.has-focus',

        isVisible: '.is-visible',
        isHidden: '.is-hidden',
        isOpened: '.is-opened',
        isSelected: '.is-selected',
        isHover: '.is-hover',
        isDisabled: '.is-disabled',
        isDirty: '.is-dirty'
    },
    fnCustomOption: false,
    fnCustomHeaderText: false,
    fnAfterChange: false,
    fadeTime: 200,
    autoHide: true,
    keyboardNav: true,
    hideHeader: false
};

const $document = $(document);
const isMobile = isMobileBrowser();
const isMobileWindows = false;

/**
 *
 * @class
 */
class StyledSelect {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom object
     * @param {Object} [options={}] - The optional plugin options
     * @param {Function} [callback] - The optional plugin callback function
     * @returns {void}
     */
    constructor(element, options = {}, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            callback && { callback }
        );

        this.version = VERSION;
        this.store = {
            $el,
            opts,
            cursorPosition: 0
        };

        this.init();
    }

    /**
     * Build replacement wrapper element.
     *
     * @returns {void}
     */
    buildWrapper () {
        const {
            $el,
            opts: { sel }
        } = this.store;

        const offset = $el.offset();
        const elOffsetTop = parseInt(offset.top);
        const elOffsetLeft = parseInt(offset.left);

        let id = guid();
        if (elOffsetTop || elOffsetLeft) {
            id = `${elOffsetTop}-${elOffsetLeft}`;
        }

        const $wrapper = $(`<${sel.wrapperTag}>`, {
            'id': getCssClass(sel.wrapperId, '#') + '--' + id,
            'class': getCssClass(sel.wrapperCss)
        });

        this.store = Object.assign(
            {},
            this.store,
            {
                id,
                $wrapper
            }
        );
    }

    /**
     * Build header element.
     *
     * @returns {void}
     */
    buildHeader() {
        const {
            $wrapper,
            id,
            opts: { hideHeader, sel }
        } = this.store;

        const $header = $(`<${sel.headerTag}>`, {
            'id': getCssClass(sel.headerId, '#') + '--' + id,
            'class': getCssClass(sel.headerCss),
            'role': 'combobox',
            'aria-activedescendant': 'option-1',
            'aria-autocomplete': 'list',
            'aria-disabled': 'false',
            'aria-expanded': 'false',
            'aria-haspopup': 'true',
            'aria-labelledby': 'option-1',
            'aria-owns': sel.contentId.substr(1) + '-' + id,
            'tabindex': '0'
        });

        const $headerIcon = $(`<${sel.headerIconTag}>`, {
            'class': getCssClass(sel.headerIconCss),
            'aria-hidden': 'true'
        }).appendTo($header);

        const $headerText = $(`<${sel.headerTextTag}>`, {
            'class': getCssClass(sel.headerTextCss)
        }).appendTo($header);

        if (hideHeader) {
            $header.addClass(getCssClass(sel.isHidden));
        }

        $header.appendTo($wrapper);

        this.store = Object.assign(
            {},
            this.store,
            {
                $header,
                $headerIcon,
                $headerText
            }
        );
    }

    /**
     * Build content element.
     *
     * @returns {void}
     */
    buildContent () {
        const store = this.store;
        const {
            $el,
            $wrapper,
            id,
            opts: { fnCustomOption, sel }
        } = store;

        const $content = $(`<${sel.contentTag}>`, {
            'id': getCssClass(sel.contentId, '#') + '--' + id,
            'class': getCssClass(sel.contentCss),
            'role': 'listbox',
            'aria-activedescendant': '',
            'aria-hidden': 'true',
            'aria-labelledby': getCssClass(sel.headerId, '#') + '--' + id,
            'tabindex': '0'
        });

        // Look for options and optgroups and create according replacements
        const $elOptGroups = $el.find(sel.optgroup);
        if ($elOptGroups.length) {
            $elOptGroups.each(function () {
                renderOptGroup(sel, $(this).attr('label'), $content, id);
                $content.append(renderOptions(sel, fnCustomOption, $(this), $content, id));
            });
        } else {
            $content.append(renderOptions(sel, fnCustomOption, $el, $content, id));
        }

        const $contentEntries = $content.find(sel.contentEntryCss);
        $content.appendTo($wrapper);

        this.store = Object.assign(
            {},
            this.store,
            {
                $content,
                $elOptGroups,
                $contentEntries
            }
        );
    }

    /**
     * Build all elements.
     *
     * @returns {void}
     */
    build() {
        const {
            $el
        } = this.store;

        /**
         * Workaround if select element is hidden via css.
         *
         * If so, trigger focus won't work for mobile ios devices.
         * We fix this issue here and set element visible (although 
         * the element is still visually hidden, see css how select 
         * is hidden to get this work).
         */
        if ($el.is(':hidden') && isMobile) {
            $el.show();
        }

        this.buildWrapper();
        this.buildHeader();
        this.buildContent();
        this.onChange();

        const { $wrapper } = this.store;
        $wrapper.prependTo($el.parent());
        $el.appendTo($wrapper);
    }

    /**
     * Handle focus event, used for api events.
     *
     * @returns {void}
     */
    onFocus() {
        const {
            $el,
            $content,
            $wrapper,
            opts: { sel }
        } = this.store;

        if (hasLength($wrapper)) {
            $wrapper.addClass(getCssClass(sel.hasFocus));
        }

        if (!isMobile && !isVisible($content)) {
            triggerEvent($el, 'show.styledSelect');
        }
    }

    /**
     * Handle blur event, used for api events.
     *
     * @returns {void}
     */
    onBlur() {
        const {
            $el,
            $content,
            $wrapper,
            opts: { sel }
        } = this.store;

        if (hasLength($wrapper)) {
            $wrapper.removeClass(getCssClass(sel.hasFocus));
        }

        if (!isMobile && isVisible($content)) {
            triggerEvent($el, 'hide.styledSelect');
        }

    }

    /**
     * Handle success event, used for api events.
     *
     * @returns {void}
     */
    onSuccess() {
        const {
            $wrapper,
            opts: { sel }
        } = this.store;

        if (hasLength($wrapper)) {
            $wrapper
                .removeClass(getCssClass(sel.hasError))
                .addClass(getCssClass(sel.hasSuccess));
        }
    }

    /**
     * Handle error event, used for api events.
     *
     * @returns {void}
     */
    onError() {
        const {
            $wrapper,
            opts: { sel }
        } = this.store;

        if (hasLength($wrapper)) {
            $wrapper
                .addClass(getCssClass(sel.hasError))
                .removeClass(getCssClass(sel.hasSuccess));
        }

    }

    /**
     * Show content list, used for api events.
     *
     * @name module:plugin.jquery.styled-select.StyledSelect#onShow
     * @function
     * @public
     *
     * @returns {void}
     */
    onShow() {
        const {
            $content,
            $header,
            $wrapper,
            opts: { autoHide, fadeTime, sel }
        } = this.store;

        if (autoHide) {
            triggerEvent($(sel.el), 'hide.styledSelect');
        }

        if (hasLength($wrapper)) {
            $wrapper
                .addClass(getCssClass(sel.isOpened))
                .addClass(getCssClass(sel.hasFocus));
            $header
                .attr('aria-expanded', 'true');
            $content
                .stop(true, true)
                .slideDown(fadeTime)
                .attr('aria-hidden', 'false')
                .addClass(getCssClass(sel.isVisible));
        }
    }

    /**
     * Hide content list, used for api events.
     *
     * @returns {void}
     */
    onHide() {
        const {
            $content,
            $header,
            $wrapper,
            opts: { fadeTime, sel }
        } = this.store;

        if (hasLength($wrapper)) {
            $wrapper
                .removeClass(getCssClass(sel.isOpened))
                .removeClass(getCssClass(sel.hasFocus));
            $header
                .attr('aria-expanded', 'false');
            $content
                .stop(true, true)
                .slideUp(fadeTime)
                .attr('aria-hidden', 'true')
                .removeClass(getCssClass(sel.isVisible));
        }
    }

    /**
     * Toggle (show/hide) content list, used for api events.
     *
     * @returns {void}
     */
    onToggle() {
        const {
            $content,
            $el
        } = this.store;

        // Trigger replacement or native select due to device
        if (!isMobile) {
            if (isVisible($content)) {
                triggerEvent($el, 'hide.styledSelect');
            } else {
                triggerEvent($el, 'show.styledSelect');
            }
        } else {
            triggerEvent($el, 'focus.styledSelect');
            if (isMobileWindows) {
                triggerEvent($el, 'click.styledSelect');
            }
        }
    }

    /**
     * Move the cursor position inside the current content list.
     *
     * @param {number} direction - The up/down direction to be moved
     * @returns {void}
     */
    onCursorMove(direction) {
        const {
            $el,
            $content,
            $contentEntries,
            cursorPosition,
            opts: { sel }
        } = this.store;

        if (!isVisible($content)) {
            return;
        }

        // Check cursor range
        const contentEntriesLength = $contentEntries.length;
        let newPosition = (cursorPosition || 0) + parseInt(direction);
        if (newPosition >= contentEntriesLength) {
            newPosition = 0;
        } else if (newPosition < 0) {
            newPosition = contentEntriesLength - 1;
        }

        /**
         * Remove css is-hover class, useful if mouse is hover content
         * and cursors are pressed to avoid duplicate highlighting.
         */
        $contentEntries.removeClass(sel.isHover);

        const contentEntryValue = $($contentEntries[newPosition]).data('value');
        $el
            .val(contentEntryValue)
            .trigger('change', [
                contentEntryValue,
                newPosition
            ]);

        this.store = Object.assign(
            {},
            this.store,
            {
                cursorPosition: newPosition
            }
        );
    }

    /**
     * Handle change event.
     *
     * @param {string} value -  The new value
     * @param {number} [index] - The optional new position in list
     * @returns {void}
     */
    onChange(value, index) {
        let store = this.store,
            headerText = '';
        const {
            $el,
            $content,
            $contentEntries,
            $header,
            $headerText,
            $option,
            $wrapper,
            opts: {
                fnAfterChange,
                fnCustomHeaderText,
                sel
            }
        } = store;

        // Check params, mobile fix
        if (!isValidValue(value)) {
            value = $el.val();
        }
        index = parseInt(index);

        // Add dirty class if value isn't default
        const cssIsDirty = sel.isDirty.substr(1);
        if (parseInt(value)) {
            $wrapper.addClass(cssIsDirty);
        } else {
            $wrapper.removeClass(cssIsDirty);
        }

        // Get selected option value and set selected attribute
        $option.each(function (i) {
            const $entry = $(this);
            let entrySelected = false;

            /**
             * Do not remove selected attribute, e.g.: '$elOption.removeAttr('selected');' - this
             * will cause unexpected behaviour on ios and firefox!!! Just let the browser decide!
             */
            if (index !== undefined && !isNaN(index) && i === index) {
                entrySelected = true;
            } else if ($entry.val() === value) {
                entrySelected = true;
            }

            if (entrySelected) {
                headerText = $entry.html();
            }
        });

        // Set selected content entry and set css classes
        $contentEntries.each(function (i) {
            const $entry = $(this);
            let entrySelected = false;

            // Remove selected class from all but the choosen one
            $entry.removeClass(getCssClass(sel.isSelected));
            if (index !== undefined && !isNaN(index) && i === index) {
                entrySelected = true;
            } else if ($entry.data('value') === value) {
                entrySelected = true;
            }

            // Selected replacement item found
            if (entrySelected) {
                const entryId = $entry.attr('id');

                $entry
                    .addClass(getCssClass(sel.isSelected));
                $header
                    .attr({
                        'aria-labelledby': entryId,
                        'aria-activedescendant': entryId
                    });
                $content
                    .attr({
                        'aria-activedescendant': entryId
                    });

                // Get styled html content and update cursor position
                headerText = $entry.html();
                store = Object.assign(
                    {},
                    store,
                    {
                        cursorPosition: $contentEntries.index($entry)
                    }
                );
            }
        });

        // Update header text
        if (hasLength($headerText)) {
            if (isFunction(fnCustomHeaderText)) {
                headerText = callFn(fnCustomHeaderText, [headerText]);
            }
            $headerText.html(headerText);
        }

        if (isFunction(fnAfterChange)) {
            callFn(fnAfterChange);
        }
    }

    /**
     * Reset styled select, unbind events and remove replacement from dom, used for api events.
     *
     * @returns {void}
     */
    onReset() {
        const {
            $content,
            $contentEntries,
            $el,
            $header,
            $wrapper,
            opts
        } = this.store;

        $header.off('.styledSelect');
        $content.off('.styledSelect');
        $contentEntries.off('.styledSelect');
        $el.off('.styledSelect');
        $document.off('.styledSelect');

        // Remove styled select and display original select element
        $el.insertBefore($wrapper);
        $wrapper.remove();

        this.store = Object.assign(
            {},
            {
                $el,
                opts,
                cursorPosition: 0
            }

        );
    }

    /**
     * Refresh replacement/ renew styled select, used for api events.
     *
     * @returns {void}
     */
    onRefresh() {
        this.onReset();
        this.build();
        this.events();
    }

    /**
     * Bind events.
     *
     * @returns {void}
     */
    events() {
        const {
            $content,
            $contentEntries,
            $el,
            $header,
            opts: {
                keyboardNav,
                sel
            }
        } = this.store;

        $header.on({
            'click.styledSelect': function (e) {
                e.preventDefault();
                e.stopPropagation();

                // Decide to display native mobile select or replacement
                if (!isMobile) {
                    $el.trigger('toggle.styledSelect');
                } else {
                    $el.trigger('focus.styledSelect');

                    /**
                     * Hack: fix for mobile windows phones,
                     * these devices doesn't show native select
                     * dialogs on focus events.
                     */
                    if (isMobileWindows) {
                        $el.trigger('click.styledSelect');
                    }
                }

                return false;
            }
        });

        $content.on({
            'click.styledSelect': function (e) {
                // Prevent content clicks which are outside the replacement list entries
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        });

        $contentEntries.on({
            'click.styledSelect': function (e) {
                const $this = $(this);

                e.preventDefault();
                e.stopPropagation();

                if ($this.hasClass('is-optgroup')) {
                    return false;
                }

                /**
                 * Make sure we got a valid value, get the new value here.
                 * This is a fallback (via data attr) for old browsers.
                 */
                let value = $this.data('value');
                if (!value) {
                    value = $this.attr('data-value');
                }

                $el.val(value)
                    .trigger('change', [value])
                    .trigger('blur');

                return false;
            },
            'mouseenter.styledSelect': function () {
                if (!isVisible($content)) {
                    return;
                }
                $(this).addClass(getCssClass(sel.isHover));
            },
            'mouseleave.styledSelect': function () {
                if (!isVisible($content)) {
                    return;
                }
                $(this).removeClass(getCssClass(sel.isHover));
            }
        });

        $el.on({

            /**
             * Writing 'change' as string doesn't work on some devices, also
             * namespacing this event isn't possible on some mobile devices!
             *
             * @param {Object} e - The jQuery event object
             * @param {string|number} value - The new select value
             * @param {number} index - The selected values position
             */
            change: (e, value, index) => {
                this.onChange(value, index);
            },

            // Provide simple api events
            'reset.styledSelect': this.onReset.bind(this),
            'refresh.styledSelect': this.onRefresh.bind(this),
            'focus.styledSelect': this.onFocus.bind(this),
            'blur.styledSelect': this.onBlur.bind(this),
            'error.styledSelect': this.onError.bind(this),
            'success.styledSelect': this.onSuccess.bind(this),
            'toggle.styledSelect': this.onToggle.bind(this),
            'show.styledSelect': this.onShow.bind(this),
            'hide.styledSelect': this.onHide.bind(this)
        });

        // Handling keyboard events
        if (keyboardNav) {
            $document.on({
                'keydown.styledSelect': (e) => {
                    if (isVisible($content)) {
                        switch (e.keyCode) {
                        case 38: // up
                            e.preventDefault();
                            this.onCursorMove(-1);
                            break;
                        case 40: // down
                            e.preventDefault();
                            this.onCursorMove(1);
                            break;
                        case 13: // enter
                            e.preventDefault();
                            $el.trigger('blur');
                            break;
                        case 27: // esc
                            $el.trigger('blur');
                            break;
                        default:
                            break;
                        }
                    }
                }
            });
        }
    }

    /**
     * Store some dom vars for faster access.
     *
     * @returns {void}
     */
    dom() {
        const {
            $el,
            opts: { sel }
        } = this.store;

        const $option = $el.find(sel.option);
        const $optgroup = $el.find(sel.optgroup);

        this.store = Object.assign(
            {},
            this.store,
            {
                $option,
                $optgroup
            }
        );
    }

    /**
     * Init plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const {
            opts: { callback }
        } = this.store;

        this.dom();
        this.build();
        this.events();

        callFn(callback);
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @name external:"jQuery.fn".styledSelect
 * @function
 *
 * @see {@link StyledSelect}
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback) {
    const $domElement = $(this);

    /**
     * Helper function to initialize plugin.
     *
     * @private
     * @param {Object} domElement - The dom element for this jquery plugin
     * @param {Object} pluginOptions - The plugin options
     * @param {Function} pluginCallback - The plugin callback after initialization
     * @returns {void}
     */
    function init(domElement, pluginOptions, pluginCallback) {
        if (!$.data(domElement, PLUGIN_DATA_STRING)) {
            $.data(domElement, PLUGIN_DATA_STRING, new StyledSelect(domElement, pluginOptions, pluginCallback));
        }
    }

    /**
     * Check if element is a select element.
     *
     * If not try to find select element in current collection and
     * run plugin for each found select element.
     */
    if (!$domElement.is('select')) {
        const $domElementSelect = $domElement.find('select');
        if ($domElementSelect.length) {
            $domElementSelect.each(function () {
                init(this, options, callback);
            });
        }

        return this;
    }

    // Default implementation
    return this.each(function () {
        init(this, options, callback);
    });
};

export default StyledSelect;
