/*global IntersectionObserver */
/**
 * jQuery plugin to load image content just if
 * the element is in view.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.1
 *
 * @example <caption>Basic plugin usage</caption>
 *  $('.img').unveil();
 *
 * @requires jquery
 * @requires intersection-observer
 * @requires utils/environment
 * @requires utils/function
 * @requires utils/load-image
 *
 * @see {@link https://www.smashingmagazine.com/2018/01/deferring-lazy-loading-intersection-observer-api/}
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */
import $ from 'jquery';
import 'intersection-observer';
import { isProduction } from '../utils/environment';
import { callFn } from '../utils/function';
import { loadImage } from '../utils/load-image';

const PLUGIN_NAME = 'unveil';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '0.0.1';

const DEFAULTS = {
    sel: {
        unveil: '.c-unveil',
        unveilHidden: '.c-unveil--hidden',
        unveilLoading: '.c-unveil--loading',
        dataSrcSet: 'data-srcset',
        srcSet: 'srcset',
        src: 'src'
    },
    rootMargin: '50px',
    threshold: 20
};

const noop = Function.prototype;

/**
 * @private
 * @param {number} [numSteps=20.0] - The threshold count
 * @returns {Array<number>}
 */
function buildThresholdList(numSteps = 20.0) {
    const thresholds = [];

    for (let i = 1.0; i <= numSteps; i = i + 1) {
        const ratio = i / numSteps;
        thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
}

/**
 * @private
 * @returns {boolean}
 */
function isPictureElementSupported() {
    return !!window.HTMLPictureElement;
}

/**
 * @class
 */
class Unveil {

    /**
     * The actual plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     * @returns {void}
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            { callback }
        );

        this.version = VERSION;
        this.cache = {
            $el,
            opts,
            isPictureElementSupported: isPictureElementSupported()
        };
        this.observer = null;

        this.handleIntersect = this.handleIntersect.bind(this);
        this.loadImage = this.loadImage.bind(this);
        this.observe = this.observe.bind(this);
        this.onError = this.onError.bind(this);

        this.init();
    }

    /**
     * Create new IntersectionObserver if possible.
     *
     * @returns {Promise}
     */
    createObserver() {
        const {
            opts
        } = this.cache;

        // Avoiding 'root' or setting it to 'null' sets it to default value: viewport
        const options = {
            root: null,
            rootMargin: opts.rootMargin || '0px',
            threshold: buildThresholdList(opts.threshold)
        };

        return new Promise((resolve, reject) => {
            if (typeof IntersectionObserver === 'undefined') {
                return reject('No IntersectionObserver available');
            }

            const observer = new IntersectionObserver(this.handleIntersect, options);
            this.observer = observer;
            resolve();
        });
    }

    /**
     * Add listener to current element.
     *
     * @returns {void}
     */
    observe() {
        const { cache, observer } = this;
        const {
            $el
        } = cache;

        observer.observe($el.get(0));
    }

    /**
     * Callback when intersection listener detects
     * visibility changes.
     *
     * @param {Array<Object>} entries - A list of dom objects
     * @returns {void}
     */
    handleIntersect(entries) {
        entries.forEach((entry) => {
            const { isIntersecting } = entry;

            // Convenience property indicating whether the observed
            // element is currently intersecting the “capturing frame” or not.
            if (isIntersecting !== undefined && isIntersecting) {
                this.loadImage(entry);
            }

            // In Microsoft Edge 15, isIntersecting property was not implemented,
            // returning undefined despite full support for IntersectionObserver otherwise.
            // This has been fixed in July 2017 though and is available since Edge 16.
            if (isIntersecting === undefined) {
                this.loadImage(entry);
            }
        });
    }

    /**
     * Callback when intersection listener detects visibility changes.
     * We simply remove the loading animation when the image is loaded
     * and fire the corresponding events.
     *
     * @param {Object} entry - A single dom object
     * @returns {void}
     */
    loadImage(entry) {
        const { cache, observer } = this;
        const {
            $el,
            opts: {
                sel: {
                    dataSrcSet,
                    srcSet,
                    src,
                    unveil,
                    unveilHidden,
                    unveilLoading
                }
            },
            isPictureElementSupported
        } = cache;
        const $entry = $(entry.target);

        /**
         * Add loaded classnames and remove observer
         * event listening for performance.
         *
         * @private
         * @returns {void}
         */
        function loaded () {
            requestAnimationFrame(function () {
                $entry
                    .addClass(unveil.substr(1))
                    .removeClass(unveilHidden.substr(1))
                    .removeClass(unveilLoading.substr(1));
                observer.unobserve($el.get(0));
                observer.disconnect();
            });
        }

        // Find image in picture
        const $entryImage = $entry.find(`img[${src}]`).first();
        const entryImage = $entryImage.get(0);
        if (!entryImage) {
            return loaded();
        }

        // The currentSrc attribute is read only and is not
        // polyfilled correctly by pictureFill, so we set
        // the src from the first srcSet we found.
        if (!isPictureElementSupported) {
            const firstSrcSet = $entry.find(`[${dataSrcSet}]`).first().attr(dataSrcSet);
            $entryImage.attr(src, firstSrcSet);
        }

        // Set visible picture soures to valid url, removing the
        // data attribute with valid sources. Using promises to
        // be able to use requestAnimationFrame for better scrolling
        // experience without jank.
        const dataSrcSets = Array.from($entry.find(`[${dataSrcSet}]`)).map(function ($entry) {
            return new Promise(function (resolve) {
                const $this = $($entry);
                const srcset = $this.attr(dataSrcSet);

                requestAnimationFrame(function () {
                    if ($this.is('img')) {
                        $this.attr(src, srcset).removeAttr(dataSrcSet);
                    } else {
                        $this.attr(srcSet, srcset).removeAttr(dataSrcSet);
                    }
                    resolve();
                });
            });
        });

        Promise.all(dataSrcSets) // eslint-disable-line promise/catch-or-return
            .catch(console.warn) // eslint-disable-line no-console
            .finally(function () {
                // Get current image source, depending on the embedded state in the
                // html markup. Fallback to data attribute.
                const { currentSrc, src } = entryImage;
                const url = currentSrc || src;

                if (!url) {
                    return loaded();
                }

                loadImage(url, loaded);
            });
    }

    /**
     * Just add some logging and try to load image on failure.
     *
     * @param {string} error - The current error reason
     * @returns {void}
     */
    onError(error) {
        const {
            $el,
            opts: {
                sel: {
                    unveil,
                    unveilHidden,
                    unveilLoading
                },
                callback
            }
        } = this.cache;

        loadImage({target: $el.get(0)}, function () {
            requestAnimationFrame(function () {
                $el
                    .addClass(unveil.substr(1))
                    .removeClass(unveilHidden.substr(1))
                    .removeClass(unveilLoading.substr(1));
            });
        });
        !isProduction() && console.warn(error); // eslint-disable-line no-console
        callFn(callback);
    }

    /**
     * Init plugin, main function.
     *
     * @returns {void}
     */
    init() {
        const {
            opts: { callback }
        } = this.cache;

        this.createObserver()
            .then(this.observe)
            .then(function () {
                return callFn(callback);
            })
            .catch(this.onError);
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link Unveil}
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback=noop] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Unveil(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
