/* global $, document */
/**
 * Handle client side rendering.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @requires @babel/polyfill
 * @requires webfontloader
 * @requires fastclick
 * @requires mark.js
 * @requires vendor/modernizr
 * @requires plugins/jquery.border
 * @requires plugins/jquery.featured
 * @requires plugins/jquery.fixed
 * @requires plugins/jquery.fullscreen
 * @requires plugins/jquery.map
 * @requires plugins/jquery.page
 * @requires plugins/jquery.slider
 * @requires plugins/jquery.styled-select
 * @requires plugins/jquery.unveil
 * @requires plugins/jquery.validate
 * @requires utils/picture-fill
 * @requires utils/tracking
 * 
 * @changelog
 * - 0.0.1 Basic functions and structure
 */
import '@babel/polyfill';
import webfontloader from 'webfontloader';
import FastClick from 'fastclick';
import Mark from 'mark.js';
import './vendor/modernizr';
import './vendor/standalone';

import './plugins/jquery.border';
import './plugins/jquery.cookie-layer';
import './plugins/jquery.featured';
import './plugins/jquery.fixed';
import './plugins/jquery.fullscreen';
import './plugins/jquery.map';
import './plugins/jquery.page';
import './plugins/jquery.slider';
import './plugins/jquery.styled-select';
import './plugins/jquery.unveil';
import './plugins/jquery.validate';

import { CLIENT_LOADED_CONSOLE_LOG } from './constants/core';
import initPicturefill from './utils/picture-fill';
import tracking from './utils/tracking';
import { getQueryString } from './utils/url';
import { configureStore } from './state/configure-store';
import { changeFeaturedSelected } from './state/featured/duck';
import { selectFeaturedSelected } from './state/featured/selector';
import { changeCookieAccepted } from './state/cookie/duck';
import { selectCookieAccepted } from './state/cookie/selector';

const store = configureStore();
let triggerReadyCallCount = 0;

// Add fastclick mobile helper
if (document.body) {
    new FastClick(document.body);
}

// Add async google webfonts
webfontloader.load({
    google: {
        families: [
            'Raleway:300',
            'Open Sans:300'
        ]
    }
});

/**
 * Check if we are really ready after initialisation. Useful
 * for css regression tests to avoid false positives.
 *
 * @private
 * @returns {void}
 */
function triggerReady() {
    triggerReadyCallCount = triggerReadyCallCount + 1;
    if (triggerReadyCallCount === 3) {
        console.log(CLIENT_LOADED_CONSOLE_LOG); // eslint-disable-line no-console
    }
}

/**
 * Init jquery plugins after the page plugin
 * decides to do so. Add tracking to interactions.
 * 
 * @private
 * @returns {void}
 */
function initPlugins() {
    const $this = $(this);
    const state = store.getState();
    const dispatch = store.dispatch;

    // Init responsive images
    $this.find('.c-unveil--loading').unveil();
    initPicturefill();

    // Init slider
    const $slider = $this.find('.m-slider');
    if ($slider.length) {
        $slider.slider({
            onMove: function ({ $currentImage, percent }) {
                $currentImage.css({
                    filter: `grayscale(${percent * 0.8}%)`
                });
                if (percent > 98) {
                    $currentImage.css({
                        filter: 'unset',
                        opacity: 'unset'
                    });
                }
            },
            onGoto: function ({ index }) {
                tracking.event(
                    'Slider',
                    'show',
                    `Image number ${index}`
                );
            },
            onClick: function () {
                this.onNext();
            }
        }, function () {
            const $slider = $(this).next('.m-slider');
            $slider.find('.c-unveil--hidden').unveil(() => {
                this.onResize();
            });
            
            setTimeout(function () {
                triggerReady();
            }, 1000);
        });
    } else {
        triggerReady();
    }

    // Init map
    const $map = $this.find('.m-map');
    if ($map.length) {
        $map.map({
            onMarkerClick: function (position) {
                tracking.event(
                    'Map',
                    'click',
                    `Marker icon, position: ${position}`
                );
            }
        }, triggerReady);
    } else {
        triggerReady();
    }

    // Init project preview
    const $featured = $this.find('.m-featured');
    if ($featured.length) {
        $featured.featured({
            selected: selectFeaturedSelected(state),
            onFilter: function (category) {
                tracking.event(
                    'Projects',
                    'filter',
                    `Filter for category: ${category}`
                );
                dispatch(changeFeaturedSelected(category));
            }
        }, function () {
            $('select').styledSelect({}, function () {
                $('.m-nav--filter').removeClass('is-loading');
            });
            $('.c-border').border();
            triggerReady();
        });
    } else {
        triggerReady();
    }

    // Init multiple plugins
    $this.find('select').styledSelect();
    $this.find('.m-form--search').validate();
    $this.find('.l-header, .m-nav--sub').fixed();
    $this.find('.c-border, .m-featured__link').border();
    $this.find('.m-layer--cookie-layer').cookieLayer({
        accepted: selectCookieAccepted(state),
        onChangeAccepted: function (accepted) {
            dispatch(changeCookieAccepted(accepted));
        }
    });
    $this.find('.m-menu__item--fullscreen').fullscreen();
    $this.find('.m-nav__overlay').off('.overlay').on({
        'click.overlay': function () {
            $('.m-nav__toggle').prop('checked', false);
        }
    });

    // Init search text highlighting
    const queryString = getQueryString();
    const query = queryString && queryString.q;
    if (query) {
        const context = document.querySelector('main');

        if (context) {
            const markInstance = new Mark(context);
            markInstance.mark(query, {
                className: 'c-mark'
            });
        }
    }

    // Hide loading layer
    $('.m-layer--loading').delay(100).fadeOut(100);

    // Add google analytics page view
    tracking.pageview();
}

// Main plugin, start routine
$('.l-body').page({}, initPlugins);
