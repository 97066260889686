/**
 * Generate random string with a defined length.
 *
 * @private
 * @param {number} [radix=16] - Number between 2 and 36 to define the base
 * @returns {string} A random string
 */
function s4(radix = 16) {
    return Math.floor((1 + Math.random()) * 0x10000).toString(radix).substring(1);
}

/**
 * Generate unique id string.
 *
 * @returns {string} The generate unique id string
 */
function guid() {
    return s4() + s4() + '-' + s4() + s4();
}

export {
    guid
};
