const ANALYTICS_KEY = 'UA-101615421-1';
const GA_SEND = 'send';
const GA_CREATE = 'create';
const GA_HIT_TYPE_PAGE_VIEW = 'pageview';
const GA_HIT_TYPE_EVENT = 'event';

/**
 * Add google analytics tracking via simple
 * interface.
 */
class Tracking {
    /**
     * Call main functions.
     * 
     * @returns {void}
     */
    constructor() {
        this.checkApi();
        this.pageview();
    }

    /**
     * Check if google script has been loaded. Otherwise create
     * a temporary object which get's flushed later.
     *
     * @returns {void}
     */
    checkApi() {
        window.ga = window.ga || function () {
            (window.ga.q = window.ga.q || []).push(arguments);
        };
        window.ga(GA_CREATE, ANALYTICS_KEY, 'auto');
    }

    /**
     * Add analytics page view.
     * 
     * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/pages}
     * @returns {void}
     */
    pageview() {
        window.ga && window.ga(GA_SEND, {
            hitType: GA_HIT_TYPE_PAGE_VIEW,
            page: location.pathname
        });
    }

    /**
     * Add analytics event.
     *
     * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/events}
     * @param {string} [eventCategory] - Typically the object that was interacted with (e.g. 'Video')
     * @param {string} [eventAction] - The type of interaction (e.g. 'play')
     * @param {string} [eventLabel] - Useful for categorizing events (e.g. 'Fall Campaign')
     * @param {string} [eventValue] - A numeric value associated with the event (e.g. 42)
     * @returns {void}
     */
    event(eventCategory, eventAction, eventLabel, eventValue) {
        window.ga && window.ga(GA_SEND, {
            hitType: GA_HIT_TYPE_EVENT,
            eventCategory: eventCategory,
            eventAction: eventAction,
            eventLabel: eventLabel,
            eventValue: eventValue
        });
    }

}
const tracking = new Tracking();

export default tracking;