/**
 * Check if move is x direction.
 *
 * @see {@link http://stephband.info/jquery.event.swipe/}
 * @param {Object} move - The mouseMove event config
 * @returns {boolean} Whether to current move is vertical or not
 */
function isMoveX(move) {
    const distX = move.distX;
    const distY = move.distY;

    return (distY > distX && distY < -distX) || (distY < distX && distY > -distX);
}

/**
 * Check if move is y direction.
 *
 * @see {@link http://stephband.info/jquery.event.swipe/}
 * @param {Object} move - The mouseMove event config
 * @returns {boolean} Whether to current move is horizontal or not
 */
function isMoveY(move) {
    const distX = move.distX;
    const distY = move.distY;

    return (distX > distY && distX < -distY) || (distX < distY && distX > -distY);
}

export {
    isMoveX,
    isMoveY
};
