/**
 * Es6 module for checking browser types and capabilities.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @requires lodash
 *
 * @changelog
 * - 0.0.1 Basic function and structure
 */
import { isFunction } from 'lodash';

const isArray = Array.isArray;

/**
 * Check and call function, check for options added for old explorers.
 *
 * @param {Function} [callback] - The function to check
 * @param {Array} [options] - The optional function arguments
 * @returns {void}
 */
function callFn(callback, options = []) {
    if (isFunction(callback)) {
        if (isArray(options) && options.length) {
            return callback.apply(callback, options);
        }
        return callback();
    }
}

export {
    callFn
};
