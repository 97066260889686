/*global requestAnimationFrame */
/**
 * jQuery plugin to render an image slider.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2017
 * @version 0.0.3
 *
 * @example <caption>Basic plugin usage</caption>
 * $('.slider').slider({}, function () {
 *    console.log("ready");
 * });
 *
 * @requires jquery
 * @requires lodash
 * @requires jquery.event.move
 * @requires vendor/requestAnimationFrame
 * @requires utils/animate
 * @requires utils/environment
 * @requires utils/function
 * @requires utils/get-css-class
 * @requires utils/move
 * @requires utils/load-image
 * @requires plugins/jquery.slider/css
 *
 * @changelog
 * - 0.0.3 Add better image scaling
 * - 0.0.2 Add requestAnimationFrame for initial builds
 * - 0.0.1 Basic function and structure
 */
import $ from 'jquery';
import { debounce, uniq } from 'lodash';

import '../vendor/jquery.event.move';
import '../vendor/requestAnimationFrame';
import { animate, stop } from '../utils/animate';
import { isProduction } from '../utils/environment';
import { callFn } from '../utils/function';
import { getCssClass } from '../utils/get-css-class';
import { isMoveY } from '../utils/move';
import { loadImage } from '../utils/load-image';
import scrollTo from '../utils/scroll-to';
import { getCssPositionObject } from './jquery.slider/css';
import { 
    getIndex,
    getScrollDirectionFactor,
    getSlideImage,
    getSlidesArray,
    isEdgeIndex
} from './jquery.slider/utils';

const noop = Function.prototype;
const MathAbs = Math.abs;
const $emptyJqueryObject = $({});

const PLUGIN_NAME = 'slider';
const PLUGIN_DATA_STRING = `plugin_${PLUGIN_NAME}`;
const VERSION = '3.0.0';

const DEFAULTS = {
    type: 'slider',
    parent: null,
    sel: {
        body: 'body',

        sliderTag: 'div',
        sliderSel: '.v-slider',
        sliderSelSlider: '.v-slider--slider',
        sliderSelGallery: '.v-slider--gallery',

        slidesTag: 'div',
        slidesSel: '.v-slider__slides',
        scrollerTag: 'ul',
        scrollerSel: '.v-slider__scroller, .m-slider__scroller',
        slideTag: 'li',
        slideSel: '.v-slider__slide',
        slideImageSel: '.v-slider__image',
        slideContentTag: 'div',
        slideContentSel: '.v-slider__content',

        slideCaption: '.env-m-gallery__caption',
        sliderCounter: '.v-slider__counter',

        bulletsTag: 'ul',
        bulletsSel: '.v-slider__bullets, .m-slider__bullets',
        bulletTag: 'li',
        bulletSel: '.v-slider__bullet, .m-slider__bullet',
        bulletLinkTag: 'button',
        bulletLinkSel: '.v-slider__bullet-link, .m-slider__bullet-link',
        bulletLinkTextTag: 'span',
        bulletLinkTextSel: '.v-slider__bullet-link-text',
        bulletsCounterTag: 'p',
        bulletsCounterSel: '.v-slider__bullets--counter, .m-slider__bullets--counter',

        timerTag: 'div',
        timerClass: '.v-slider__timer',
        timerRotatorTag: 'div',
        timerRotatorSel: '.v-slider__timer__rotator',
        timerMaskTag: 'div',
        timerMaskSel: '.v-slider__timer__mask',

        arrowTag: 'button',
        arrowSel: '.v-slider__arrow',
        arrowRightSel: '.v-slider__arrow--right, .m-slider__arrow--right',
        arrowLeftSel: '.v-slider__arrow--left, .m-slider__arrow--left',
        arrowDownSel: '.v-slider__arrow--down, .m-slider__arrow--down',
        arrowIconTag: 'i',
        arrowIconSel: '.v-slider__arrow-icon',

        btnFullscreenEnterTag: 'button',
        btnFullscreenEnterSel: '.v-slider__btn-fullscreen--enter',
        btnFullscreenExitTag: 'button',
        btnFullscreenExitSel: '.v-slider__btn-fullscreen--exit',

        loadingTag: 'div',
        loadingSel: '.v-slider__loading',

        isFullscreen: '.is-fullscreen',
        isFullscreenFallback: '.is-fullscreen--fallback',

        isCurrentSel: '.is-current',
        isNextSel: '.is-next',
        isPreviousSel: '.is-previous',
        isHalfSel: '.is-half',
        isGrabSel: '.is-grab',
        isGrabbingSel: '.is-grabbing',
        isLoading: '.is-loading',
        isResizing: '.is-resizing',
        isActive: '.is-active',
        isHidden: '.is-hidden',
        isVisuallyHidden: '.is-visually-hidden'
    },
    i18n: {
        arrowLeftLabel: 'Vorheriges Bild',
        arrowRightLabel: 'Nächstes Bild'
    },
    fadeTime: 500,
    treshold: 0.15,
    ignoreTags: {
        textarea: true,
        input: true,
        select: true,
        button: true
    },
    onClick: noop,
    onGoto: noop,
    onMouseEnter: noop,
    onMouseLeave: noop,
    onMove: noop,
    onNext: noop,
    onPrevious: noop,
    onResize: noop,
    onVisibilitychange: noop
};
const CURSOR = {
    GRAB: 'grab',
    GRABBING: 'grabbing'
};
const IMG = 'img';

/**
 * @class
 */
class Slider {

    /**
     * The actual slider plugin constructor.
     *
     * @param {Object} element - The dom element
     * @param {Object} [options] - The plugin options
     * @param {Function} [callback] - The plugin callback after initialization
     */
    constructor(element, options, callback) {
        const $el = $(element);
        const opts = $.extend(
            true,
            {},
            DEFAULTS,
            options,
            { callback }
        );

        this.version = VERSION;
        this.cache = {
            $el,
            opts
        };

        this.onClick = this.onClick.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.onResize = debounce(this.onResize.bind(this), 100);
        this.onSwipeEnd = this.onSwipeEnd.bind(this);
        this.onSwipeEnd = this.onSwipeEnd.bind(this);
        this.onSwipeMove = this.onSwipeMove.bind(this);
        this.onSwipeStart = this.onSwipeStart.bind(this);
        this.onVisibilitychange = this.onVisibilitychange.bind(this);
        this.setDimension = this.setDimension.bind(this);
        this.onDown = this.onDown.bind(this);

        this.buildArrows = this.buildArrows.bind(this);
        this.buildBullets = this.buildBullets.bind(this);
        this.buildLoading = this.buildLoading.bind(this);
        this.buildScroller = this.buildScroller.bind(this);
        this.buildSlides = this.buildSlides.bind(this);
        this.hideLoading = this.hideLoading.bind(this);
        this.setCursor = this.setCursor.bind(this);

        return this.init();
    }

    /**
     * Handle touch swipe start event.
     *
     * @param {Object} [event] - The event object
     * @returns {void}
     */
    onSwipeStart(event) {
        const {
            hasSwipeNav,
            isAnimating,
            index,
            xWidth,
            yWidth
        } = this.cache;

        this.resetSwipe();

        if (!hasSwipeNav || isAnimating) {
            return false;
        }

        const {
            distX,
            distY
        } = event;

        if (isMoveY({ distX, distY })) {
            event.preventDefault();
        }

        const xSwipe = (index * xWidth) + distX;
        const ySwipe = (index * yWidth) + distY;

        this.cache = Object.assign(
            this.cache,
            {
                xSwipeStart: xSwipe,
                ySwipeStart: ySwipe,
                xSwipeCurrent: xSwipe,
                ySwipeCurrent: ySwipe,
                xSwipeEnd: null,
                ySwipeEnd: null,
                xSwipeDist: 0,
                ySwipeDist: 0,
                isDragging: true
            }
        );
    }

    /**
     * Handle touch swipe move event.
     *
     * @param {Object} [e] - The event object
     * @returns {void}
     */
    onSwipeMove(e) {
        const {
            hasSwipeNav,
            index,
            isAnimating,
            isDragging,
            xSwipeStart,
            xWidth,
            ySwipeStart,
            yWidth,
            $slides,
            opts: { sel }
        } = this.cache;

        if (!hasSwipeNav || !isDragging || isAnimating) {
            return false;
        }

        $slides.removeClass(getCssClass(sel.isActive));
        const xSwipe = (index * xWidth) + e.distX;
        const ySwipe = (index * yWidth) + e.distY;
        const xSwipeDist = xSwipeStart - xSwipe;
        const ySwipeDist = ySwipeStart - ySwipe;

        this.cache = Object.assign(
            this.cache,
            {
                xSwipeCurrent: xSwipe,
                ySwipeCurrent: ySwipe,
                xSwipeDist,
                ySwipeDist
            }
        );

        this.setCursor(CURSOR.GRABBING);
        this.moveScroller(
            (index * xWidth) + xSwipeDist,
            (index * yWidth) + ySwipeDist
        );
    }

    /**
     * Handle touch swipe end event.
     *
     * @returns {void}
     */
    onSwipeEnd() {
        const {
            hasSwipeNav,
            isDragging,
            isAnimating,
            index,
            xSwipeDist,
            treshold
        } = this.cache;

        if (!hasSwipeNav || !isDragging || isAnimating) {
            return false;
        }

        if (MathAbs(xSwipeDist) >= treshold) {
            if (xSwipeDist > 0) {
                this.onNext();
            }
            if (xSwipeDist < 0) {
                this.onPrevious();
            }
        } else {
            this.onGoto(index);
        }

        this.resetSwipe();
    }

    /**
     * Reset all swipe variables in cache.
     *
     * @returns {void}
     */
    resetSwipe() {
        this.setCursor();
        this.cache = Object.assign(
            this.cache,
            {
                xSwipeStart: null,
                ySwipeStart: null,
                xSwipeCurrent: null,
                ySwipeCurrent: null,
                xSwipeEnd: null,
                ySwipeEnd: null,
                xSwipeDist: 0,
                ySwipeDist: 0,
                isDragging: false
            }
        );
    }

    /**
     * Get slide element by index.
     *
     * @param {number} index - The slides index
     * @returns {jQuery} The jquery slide element
     */
    getSlide(index) {
        const { $slides, slidesLength } = this.cache;

        if (!slidesLength) {
            return $emptyJqueryObject;
        }
        return $slides.eq(getIndex(index, slidesLength));
    }

    /**
     * Set mouse cursor css by type.
     *
     * @param {string} [type='grab'] - One of 'grabbing', 'grab'
     * @returns {void}
     */
    setCursor(type = CURSOR.GRAB) {
        const {
            $scroller,
            hasSwipeNav,
            opts: { sel }
        } = this.cache;

        if (!hasSwipeNav) {
            return;
        }

        const isGrabbing = getCssClass(sel.isGrabbingSel);
        const isGrab = getCssClass(sel.isGrabSel);

        let $cursorTargets = $emptyJqueryObject;
        $cursorTargets = $cursorTargets.add($scroller);

        switch (type) {
        case CURSOR.GRABBING:
            $cursorTargets
                .addClass(isGrabbing)
                .removeClass(isGrab);
            break;
        default:
            $cursorTargets
                .removeClass(isGrabbing)
                .addClass(isGrab);
            break;
        }
    }

    /**
     * Collect children to create slides.
     *
     * @returns {jQuery} The collection of slides
     */
    getChilds() {
        const { $el } = this.cache;
        const $children = $el.children();

        return $children.filter(function () {
            const $slideImage = getSlideImage($(this));

            return !!$slideImage.length;
        });
    }

    /**
     * Build basic slider wrapper.
     *
     * @returns {Promise} Async future which resolves after success
     */
    buildSlider() {
        return new Promise((resolve) => {
            const {
                $el,
                $parent,
                opts: { sel }
            } = this.cache;

            requestAnimationFrame(() => {
                const $slider = $(`<${sel.sliderTag}>`)
                    .addClass(uniq([
                        getCssClass(sel.sliderSel),
                        getCssClass(sel.sliderSelSlider),
                        $el.attr('class')
                    ]).join(' '))
                    .on({
                        'click': this.onClick
                    })
                    .appendTo($parent);

                this.cache.$slider = $slider;
                resolve();
            });
        });
    }

    /**
     * Build basic slider wrapper.
     *
     * @returns {Promise} Async future which resolves after success
     */
    buildLoading() {
        return new Promise((resolve) => {
            const {
                $slider,
                opts: { sel }
            } = this.cache;

            requestAnimationFrame(() => {
                const $loading = $(`<${sel.loadingTag}>`)
                    .addClass(getCssClass(sel.loadingSel))
                    .appendTo($slider);

                this.cache.$loading = $loading;
                resolve();
            });
        });
    }

    /**
     * Build scroll handler.
     *
     * @returns {Promise} Async future which resolves after success
     */
    buildScroller() {
        return new Promise((resolve) => {
            const {
                $slider,
                index,
                slidesLength,
                opts: { sel }
            } = this.cache;

            requestAnimationFrame(() => {
                const $scroller = $(`<${sel.scrollerTag}>`, {
                    'aria-orientation': 'horizontal',
                    'aria-valuemin': 0,
                    'aria-valuemax': slidesLength,
                    'aria-valuenow': index,
                    'role': 'slider'
                })
                    .addClass(getCssClass(sel.scrollerSel))
                    .on({
                        'movestart.slider': this.onSwipeStart,
                        'move.slider': this.onSwipeMove,
                        'moveend.slider': this.onSwipeEnd,
                        'mouseenter.slider': this.onMouseEnter,
                        'mouseleave.slider': this.onMouseLeave
                    })
                    .appendTo($slider);

                this.cache.$scroller = $scroller;
                resolve();
            });
        });
    }

    /**
     * Build slides by coping children.
     *
     * @returns {Promise} Async future which resolves after success
     */
    buildSlides() {
        return new Promise((resolve) => {
            const {
                $scroller,
                opts: { sel }
            } = this.cache;

            requestAnimationFrame(() => {
                const $childs = this.getChilds();
                const slidesArray = getSlidesArray($childs, sel);

                $scroller.append(slidesArray).attr({
                    'aria-valuemax': slidesArray.length - 1
                });

                const $slides = $scroller.find(sel.slideSel);
                const slidesLength = $slides.length;
                const $slidesImages = $slides.find(IMG);

                this.cache = Object.assign(
                    this.cache,
                    {
                        $slides,
                        slidesLength,
                        $slidesImages,
                        hasLoop: slidesLength >= 3,
                        hasSwipeNav: slidesLength > 1
                    }
                );
                resolve();
            });
        });
    }

    /**
     * Build left and right navigation arrows.
     *
     * @returns {Promise} Async future which resolves after success
     */
    buildArrows() {
        const {
            $slider,
            slidesLength,
            opts: { i18n, sel }
        } = this.cache;

        if (slidesLength <= 1) {
            return Promise.resolve();
        }
        const selIsVisuallyHidden = getCssClass(sel.isVisuallyHidden);
        const {
            arrowLeftLabel,
            arrowRightLabel,
            arrowDownLabel
        } = i18n;

        const $arrowRight = $(`<${sel.arrowTag}>`, {
            'role': 'button',
            'title': arrowRightLabel
        })
            .addClass(getCssClass(sel.arrowRightSel))
            .html(`<span class="${selIsVisuallyHidden}">${arrowRightLabel}</span>`)
            .on({
                'click.slider': (e) => {
                    e.preventDefault();
                    this.onNext(e);
                }
            });

        const $arrowLeft = $(`<${sel.arrowTag}>`, {
            'role': 'button',
            'title': arrowLeftLabel
        })
            .addClass(getCssClass(sel.arrowLeftSel))
            .html(`<span class="${selIsVisuallyHidden}">${arrowLeftLabel}</span>`)
            .on({
                'click.slider': (e) => {
                    e.preventDefault();
                    this.onPrevious(e);
                }
            });

        const $arrowDown = $(`<${sel.arrowTag}>`, {
            'role': 'button',
            'title': arrowDownLabel
        })
            .addClass(getCssClass(sel.arrowDownSel))
            .html(`<span class="${selIsVisuallyHidden}">${arrowDownLabel}</span>`)
            .on({
                'click.slider': (e) => {
                    e.preventDefault();
                    this.onDown(e);
                }
            });

        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                $arrowRight.appendTo($slider);
                $arrowLeft.appendTo($slider);
                $arrowDown.appendTo($slider);

                this.cache = Object.assign(
                    this.cache,
                    {
                        $arrowLeft,
                        $arrowRight,
                        $arrowDown
                    }
                );
                resolve();
            });
        });
    }

    /**
     * Build bullets list navigation
     *
     * @returns {Promise} Async future which resolves after success
     */
    buildBullets() {
        const {
            $slider,
            slidesLength,
            opts: { sel }
        } = this.cache;

        if (slidesLength <= 1) {
            return Promise.resolve();
        }

        const $bulletList = $(`<${sel.bulletsTag}>`, {
            'role': 'menu'
        }).addClass(getCssClass(sel.bulletsSel));

        let bullets = [];
        for (let index = 0; index < slidesLength; index = index + 1) {
            const $bullet = $(`<${sel.bulletTag}>`).addClass(getCssClass(sel.bulletSel));
            $(`<${sel.bulletLinkTag}>`, {
                'role': 'menuitem',
                'tabindex': '-1'
            })
                .on({
                    'click.slider': (event) => {
                        event && event.preventDefault();
                        this.onGoto(index);
                    }
                })
                .addClass(getCssClass(sel.bulletLinkSel))
                .data('index', index)
                .appendTo($bullet);
            bullets.push($bullet);
        }

        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                $bulletList.append(bullets).appendTo($slider);
                const $bullets = $bulletList.find(sel.bulletTag);
                const $bulletCounter = $(`<${sel.bulletsCounterTag}>`)
                    .addClass(getCssClass(sel.bulletsCounterSel))
                    .appendTo($slider);
                const bulletListWidth = bullets[0].outerWidth(true) * slidesLength;

                this.cache = Object.assign(
                    this.cache,
                    {
                        $bulletList,
                        $bullets,
                        $bulletCounter,
                        bulletListWidth
                    }
                );
                resolve();
            });
        });
    }

    /**
     * Hide slider loading layer.
     *
     * @returns {void}
     */
    hideLoading() {
        this.cache.$slider.removeClass(
            getCssClass(this.cache.opts.sel.isLoading)
        );
    }

    /**
     * Main build function.
     *
     * @returns {Promise} Async future which resolves after success
     */
    build() {
        return this.buildSlider()
            .then(this.buildLoading)
            .then(this.buildScroller)
            .then(this.buildSlides)
            .then(this.buildArrows)
            .then(this.buildBullets)
            .then(this.setCursor)
            .catch(function (reason) {
                !isProduction() && console.warn(reason); // eslint-disable-line no-console
            });
    }

    /**
     * Set single slide's position by css.
     *
     * @param {number} index - The slides index
     * @param {number} [x=0] - The x position to be moved
     * @param {number} [y=0] - The y position to be moved
     * @returns {void}
     */
    moveSlide(index, x = 0, y = 0) {
        const $slide = this.getSlide(index);

        $slide.css(getCssPositionObject(x, y));
    }

    /**
     * Set scroller offset position.
     *
     * @param {number} [x=0] - The x position to be moved
     * @param {number} [y=0] - The y position to be moved
     * @returns {void}
     */
    moveScroller(x = 0, y = 0) {
        const {
            $scroller,
            index,
            xWidth,
            opts: { onMove }
        } = this.cache;

        let xMoved = x * -1,
            yMoved = y * 0;

        const relativeDistance = x - (index * xWidth);
        const percent = 100 * MathAbs(relativeDistance) / xWidth;

        const $slide = this.getSlide(index);
        const $img = getSlideImage($slide);

        $scroller
            .css(getCssPositionObject(xMoved, yMoved))
            .attr({
                'aria-valuenow': index
            });

        callFn(onMove.bind(this, {
            $currentImage: $img,
            $currentSlide: $slide,
            percent,
            currentIndex: index
        }));
    }

    /**
     * Animate scroller offset position.
     *
     * @param {Object} options - The animating options
     * @returns {void}
     */
    animateScroller(options) {
        const {
            timers,
            opts: { fadeTime }
        } = this.cache;
        const {
            callback = noop,
            xDest,
            xFrom,
            yDest,
            yFrom
        } = options;

        const xDelta = MathAbs(xDest - xFrom);
        const yDelta = MathAbs(yDest - yFrom);
        const xFactor = getScrollDirectionFactor(xFrom, xDest);
        const yFactor = getScrollDirectionFactor(yFrom, yDest);

        /**
         * Will be called at each animation step.
         *
         * @private
         * @param {number} t - The current animation state (0 - 1)
         * @param {number} requestId - The current animation frame request id
         * @returns {void}
         */
        const stepFunction = (t, requestId) => {
            timers.animate = requestId;
            this.moveScroller(
                (xFactor * xDelta * t) + xFrom,
                (yFactor * yDelta * t) + yFrom
            );
        };

        /**
         * Will be called after the animation run.
         *
         * @private
         * @returns {void}
         */
        const finished = () => {
            timers.animate = null;
            callFn(callback);
        };

        stop(timers.animate);
        timers.animate = animate(stepFunction, finished, fadeTime);
    }

    /**
     * Load image source async and call handlers.
     *
     * @param {string} src - The image source url
     * @param {Function} [loaded] - The loaded handler
     * @returns {void}
     */
    loadImage(src, loaded) {
        loadImage(src, function (imageDimensions) {
            loaded(imageDimensions);
        });
    }

    /**
     * Show a slide by index.
     *
     * @param {number} [newIndex=0] - The new slide index to be moved to
     * @returns {void}
     */
    onGoto(newIndex = 0) {
        const {
            $bulletCounter,
            $bullets,
            $slides,
            index,
            isAnimating,
            opts: { sel, onGoto },
            slidesLength,
            xSwipeDist,
            xWidth,
            ySwipeDist,
            yWidth
        } = this.cache;

        if (isAnimating) {
            return;
        }

        const normalizedIndex = getIndex(newIndex, slidesLength);
        const edgeIndex = isEdgeIndex(newIndex, slidesLength)
            ? newIndex
            : normalizedIndex;
        const xDest = xWidth * edgeIndex;
        const yDest = yWidth * edgeIndex;
        const xFrom = (xWidth * index) + xSwipeDist;
        const yFrom = (yWidth * index) + ySwipeDist;
        const cssClassNameActive = getCssClass(sel.isActive);

        const $slide = this.getSlide(normalizedIndex);
        const $img = getSlideImage($slide);
        const img = $img.get(0);

        /**
         * Handle image loaded events.
         *
         * @private
         * @param {Object} imageDimensions - Single image dimensions
         * @returns {void}
         */
        const loaded = (imageDimensions) => {
            this.setDimension(imageDimensions);
            this.moveScroller(
                this.cache.xWidth * normalizedIndex,
                this.cache.yWidth * normalizedIndex
            );

            $slides.removeClass(cssClassNameActive);
            $slide.addClass(cssClassNameActive);

            $bullets
                .removeClass(cssClassNameActive)
                .eq(normalizedIndex)
                .addClass(cssClassNameActive);
            $bulletCounter.text(`${normalizedIndex + 1}/${slidesLength}`);

            callFn(onGoto.bind(this, {
                index: normalizedIndex,
                $slides,
                $currentSlide: $slide,
                $currentImage: $img
            }));
        };

        /**
         * Handle animation end.
         *
         * @private
         * @returns {void}
         */
        const callback = () => {
            requestAnimationFrame(() => {
                this.cache = Object.assign(
                    this.cache,
                    {
                        index: normalizedIndex,
                        isAnimating: false
                    }
                );

                this.loadImage(img.currentSrc || img.src, loaded);
            });
        };

        this.cache = Object.assign(
            this.cache,
            {
                isAnimating: true
            }
        );

        this.animateScroller({
            xDest,
            yDest,
            xFrom,
            yFrom,
            callback
        });
    }

    /**
     * Show next slide.
     *
     * @param {Object} [event] - The event object
     * @returns {void}
     */
    onNext(event) {
        const {
            index,
            opts: { onNext }
        } = this.cache;

        event && event.preventDefault();

        this.onGoto(index + 1);
        callFn(onNext.bind(this));
    }

    /**
     * Show previous slide.
     *
     * @param {Object} [event] - The event object
     * @returns {void}
     */
    onPrevious(event) {
        const {
            index,
            opts: { onPrevious }
        } = this.cache;

        event && event.preventDefault();

        this.onGoto(index - 1);
        callFn(onPrevious.bind(this));
    }

    /**
     * Handle down click behaviour.
     *
     * @param {Object} event - The click event object
     * @returns {void}
     */
    onDown(event) {
        const {
            $slider,
            opts: { onDown }
        } = this.cache;

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        scrollTo({
            top: $slider.offset().top + $slider.height(),
            callback: function () {
                callFn(onDown);
            }
        });
    }

    /**
     * Handle default image click behaviour.
     *
     * @param {Object} event - The click event object
     * @returns {void}
     */
    onClick(event) {
        const { opts: { onClick } } = this.cache;

        event && event.preventDefault();

        callFn(onClick.bind(this));
    }

    /**
     * Handle visibility changes.
     *
     * @returns {void}
     */
    onVisibilitychange() {
        callFn(this.cache.opts.onVisibilitychange);
    }

    /**
     * Handle mouse enter events.
     *
     * @returns {void}
     */
    onMouseEnter() {
        callFn(this.cache.opts.onMouseEnter);
    }

    /**
     * Handle mouse leave events.
     *
     * @returns {void}
     */
    onMouseLeave() {
        callFn(this.cache.opts.onMouseLeave);
    }

    /**
     * Setting dimensions.
     *
     * @param {Object} [imageDimensions={}] - Single image dimensions
     * @returns {void}
     */
    setDimension(imageDimensions = {}) {
        const {
            $scroller,
            $slider,
            $slides,
            $slidesImages,
            $win,
            hasLoop,
            index,
            opts: { treshold },
            slidesLength
        } = this.cache;

        const winHeight = $win.height();

        // @TODO: Adding small tresholds to avoid false and to small values for
        // slow network connections. Needs to be fixed with blank.gif bug
        const winHeightMax = winHeight - 90;
        const imageDimensionsHeight = imageDimensions.height;

        // eslint-disable-next-line no-mixed-operators
        const imgHeight = imageDimensionsHeight && imageDimensionsHeight > 30
            ? imageDimensionsHeight
            : winHeightMax;

        const height = imgHeight > winHeightMax
            ? winHeightMax
            : imgHeight;
        const width = $slider.width();
        this.cache = Object.assign(
            this.cache,
            {
                xWidth: width,
                yWidth: height,
                treshold: width * treshold,
                winWidth: $win.width(),
                winHeight: winHeight
            }
        );

        $slidesImages
            .width(width)
            .height(height);
        $slides
            .width(width)
            .each((slideIndex) => {
                this.moveSlide(slideIndex, width * slideIndex);
            });
        $scroller
            .width(width * slidesLength)
            .height(height);

        // Update prev and next slide positions, mainly for edge cases
        if (hasLoop) {
            this.moveSlide(index - 1, width * (index - 1));
            this.moveSlide(index + 1, width * (index + 1));
        }
    }

    /**
     * Handle window resize.
     *
     * @returns {void}
     */
    onResize() {
        const {
            index,
            opts: { sel, onResize },
            $slider
        } = this.cache;

        /**
         * Handle resize functions after image is loaded.
         *
         * @private
         * @param {*} imageDimensions - Single image dimensions
         * @returns {void}
         */
        const doResize = (imageDimensions) => {
            this.setDimension(imageDimensions);
            this.onGoto(index);
            $slider.removeClass(getCssClass(sel.isResizing));

            callFn(onResize.bind(this));
        };

        const $slide = this.getSlide(index);
        const $child = $slide.children().first();
        const $image = getSlideImage($child).first();

        // Just set new dimensions if there is no image content
        if (!$image.length) {
            return doResize();
        }

        // Wait for image to be loaded before setting dimensions
        const img = $image.get(0);
        this.loadImage(img.currentSrc || img.src, doResize);
    }

    /**
     * Initialize all events.
     *
     * @returns {void}
     */
    initEvents() {
        const {
            $doc,
            $slider,
            $win,
            opts: { sel }
        } = this.cache;

        /**
         * Small helper to avoid heavy calculation.
         *
         * @private
         * @returns {void}
         */
        const doResize = () => {
            $slider.addClass(getCssClass(sel.isResizing));
            this.onResize();
        };

        $slider.off('.slider').on({
            'next.slider': this.onNext,
            'previous.slider': this.onPrevious,
            'resize.slider': doResize,
            'goto.slider': (e, index) => {
                this.onGoto(index);
            }
        });
        $doc.off('.slider').on({
            'visibilitychange.slider': this.onVisibilitychange
        });

        $win.off('.slider').on({
            'resize.slider': doResize,
            'orientationchange.slider': doResize
        });
    }

    /**
     * Initialize all basic cache variables.
     *
     * @returns {void}
     */
    initCache() {
        const { $el, opts: { sel } } = this.cache;
        const $empty = $({});
        const $win = $(window);

        this.cache = Object.assign(
            this.cache,
            {
                index: 0,
                treshold: 0,
                slidesLength: 0,

                xWidth: 0,
                yWidth: 0,
                xMax: 0,
                yMax: 0,
                winWidth: $win.width(),
                winHeight: $win.height(),

                xSwipeStart: null,
                ySwipeStart: null,
                xSwipeCurrent: null,
                ySwipeCurrent: null,
                xSwipeEnd: null,
                ySwipeEnd: null,
                xSwipeDist: 0,
                ySwipeDist: 0,
                isDragging: false,

                timers: {},

                hasSwipeNav: true,
                hasLoop: true,
                isAnimating: false,

                $scroller: $empty,
                $slider: $empty,
                $slides: $empty,
                $bulletList: $empty,
                $bullets: $empty,
                $bulletCounter: $empty,
                bulletListWidth: 0,

                $win: $win,
                $doc: $(document),
                $body: $(sel.body),
                $parent: $el.parent()
            }
        );
    }

    /**
     * Init plugin, main function.
     *
     * @returns {Promise}
     */
    init() {
        const {
            $el,
            opts: { callback }
        } = this.cache;

        // Return early if there is nothing to do
        const $childs = this.getChilds();
        if (!$childs.length) {
            callFn(callback);
            return Promise.resolve();
        }

        this.initCache();
        return this.build()
            .then(() => this.initEvents())
            .then(() => this.onResize())
            .then(() => this.onGoto())
            .catch(() => callFn(callback))
            .then(() => {
                $el.hide();
                callFn(callback);
                return this.hideLoading();
            });
    }
}

/**
 * A really lightweight jquery plugin wrapper around the constructor,
 * preventing against multiple instantiations.
 *
 * There is also the current plugin instance available
 * via the data attribute to call plugin prototype functions
 * from outside.
 *
 * @function
 * @see {@link Slider}
 * @param {Object} [options] - The plugin options
 * @param {Function} [callback=noop] - The plugin callback after initialization
 * @returns {jQuery} The current jquery object for chaining
 */
$.fn[PLUGIN_NAME] = function (options, callback = noop) {
    return this.each(function () {
        if (!$.data(this, PLUGIN_DATA_STRING)) {
            $.data(this, PLUGIN_DATA_STRING, new Slider(this, options, callback.bind(this)));
        }
    });
};

export {
    PLUGIN_DATA_STRING
};
